import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useCallback,
} from "react";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import {
  getProfile,
  updateProfile,
  updatePhoto,
  getFriendRequests,
  searchUser,
  getUserFriends,
  sendFriendRequest,
  searchUserAdvance,
  getFriends,
  acceptFriendRequest,
  cancelFriendRequest,
  unFriend,
  getFriendsWithMessages,
  addDevice,
  removeDevice,
  getFamewall,
} from "../../api/profile";
import { getFirebaseDeviceToken } from "../../utils";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const [likesList, setLikesList] = useState([]);
  const [search, setSearch] = useState([]);
  const [friends, setFriends] = useState([]);
  const [friendRequestsSent, setFriendRequestsSent] = useState([]);
  const [friendRequestsReceived, setFriendRequestsReceived] = useState([]);
  const [friendsList, setFriendsList] = useState([]);
  const [friendRequestsSentList, setFriendRequestsSentList] = useState([]);
  const [friendRequestsReceivedList, setFriendRequestsReceivedList] = useState(
    []
  );
  const [famewall, setFamewall] = useState([]);

  const [friendsWithMessages, setFriendsWithMessages] = useState([]);

  const { openToast } = useContext(ToastContext);
  const { setLoader } = useContext(LoaderContext);

  const doSearchUsers = useCallback((string) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      searchUser(string)
        .then(async (users) => {
          setSearch(users);
          setLoader(false);
          resolve(users);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doSearchUsersAdvance = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      searchUserAdvance(data)
        .then(async (users) => {
          setLoader(false);
          resolve(users);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetFriendRequests = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      // setLoader(true);
      getFriendRequests()
        .then(async (requests) => {
          // setLoader(false);
          setFriendRequestsSent(requests.sent);
          setFriendRequestsReceived(requests.receive);

          let sent = [];
          let received = [];
          for (const req of requests.sent) {
            sent.push(req.to._id);
          }

          for (const req1 of requests.receive) {
            received.push(req1.from._id);
          }

          setFriendRequestsReceivedList(received);
          setFriendRequestsSentList(sent);

          resolve(requests);
        })
        .catch((err) => {
          // setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doAddDevice = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      const device = await getFirebaseDeviceToken();
      addDevice({ device })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doRemoveDevice = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      const device = await getFirebaseDeviceToken();

      removeDevice({ device })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetFriends = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      getFriends()
        .then(async (frnds) => {
          setFriends(frnds);
          resolve(frnds);
        })
        .catch((err) => {
          // setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetUserFriends = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getUserFriends(id)
        .then(async (res) => {
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doSendFriendRequest = useCallback((to) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      sendFriendRequest(to)
        .then(async () => {
          setLoader(false);
          await doGetProfile();
          // await doGetFriends();
          // await doGetFriendRequests();
          resolve();
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doCancelFriendRequest = useCallback((to) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      cancelFriendRequest(to)
        .then(async () => {
          setLoader(false);
          await doGetProfile();

          // await doGetFriends();
          // await doGetFriendRequests();
          resolve();
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doAcceptFriendRequest = useCallback((payload) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      acceptFriendRequest(payload)
        .then(async () => {
          setLoader(false);
          await doGetProfile();
          // await getFriendRequests();
          // await getFriends();
          resolve();
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doUnFriend = useCallback((payload) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      unFriend(payload)
        .then(async () => {
          setLoader(false);
          await doGetProfile();

          // await getFriendRequests();
          // await getFriends();
          resolve();
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetProfile = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      getProfile()
        .then((ret) => {
          setLikesList(ret.likes);
          setFriendsList(ret.friendsList);
          setFriends(ret.friends);
          setFriendRequestsReceived(ret.friendRequestsReceived);
          setFriendRequestsSent(ret.friendRequestsSent);

          let sent = [];
          let received = [];
          for (const req of ret.friendRequestsSent) {
            sent.push(req.to._id);
          }

          for (const req1 of ret.friendRequestsReceived) {
            received.push(req1.from._id);
          }
          setFriendRequestsReceivedList(received);
          setFriendRequestsSentList(sent);

          setProfile(ret);
          resolve(ret);
        })
        .catch((err) => {
          if (err !== "notfound") {
            openToast("error", err);
          }
          reject(err);
        });
    });
  }, []);

  const doUpdateProfile = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      updateProfile(data)
        .then(async (ret) => {
          setLoader(false);
          openToast("success", "Profile Updated");

          await doGetProfile();
          resolve(ret);
        })
        .catch((err) => {
          setLoader(false);
          reject(err);
        });
    });
  }, []);

  const doUpdateProfilePhoto = useCallback((image) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      updatePhoto(image)
        .then(async (ret) => {
          setLoader(false);
          setProfile(ret);
          resolve(ret);
        })
        .catch(function (err) {
          setLoader(false);
          reject(err);
        });
    });
  }, []);

  const doGetFriendsWithMessages = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getFriendsWithMessages()
        .then(async (ret) => {
          setLoader(false);
          setFriendsWithMessages(ret);
          resolve(ret);
        })
        .catch(function (err) {
          setLoader(false);
          reject(err);
        });
    });
  }, []);
  const doGetFamewall = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getFamewall()
        .then(async (ret) => {
          setLoader(false);
          setFamewall(ret);
          resolve(ret);
        })
        .catch(function (err) {
          setLoader(false);
          reject(err);
        });
    });
  }, []);
  return (
    <ProfileContext.Provider
      value={{
        likesList,
        setLikesList,
        search,
        friends,
        friendsList,
        profile,
        setProfile,
        doGetProfile,
        doUpdateProfile,
        doUpdateProfilePhoto,
        friendRequestsReceived,
        friendRequestsReceivedList,
        friendRequestsSent,
        friendRequestsSentList,
        doSendFriendRequest,
        doGetFriendRequests,
        doGetFriends,
        doGetUserFriends,
        doSearchUsers,
        doSearchUsersAdvance,
        doAcceptFriendRequest,
        doCancelFriendRequest,
        doUnFriend,
        doGetFriendsWithMessages,
        friendsWithMessages,
        doAddDevice,
        doRemoveDevice,
        doGetFamewall,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
