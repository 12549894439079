import React, { useContext } from 'react';
import { HomeOutlined,VideoCameraOutlined, UsergroupDeleteOutlined, UserOutlined, MessageOutlined } from '@ant-design/icons';
import { CallContext } from '../../../context';
import { useHistory } from 'react-router-dom';
const Sidebare = () => {
  const history = useHistory();
  const { activeRoute, updateActiveRoute } = useContext(CallContext);
  const navigateTo = r => {
  updateActiveRoute(r);
  history.push(`/${r}`)
  }
    return(
        <div className="home-sidebare" >

        {
          activeRoute === 'home' ? (
            <div onClick={() => navigateTo('home')} className="home-sidebare-item active-sidebare-item" >
              <HomeOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Home
              </div>
            </div>
          ) : (
            <div onClick={() => navigateTo('home')} className="home-sidebare-item" >
              <HomeOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Home
              </div>
            </div>
            )}
        
          {/* {
            activeRoute === 'watch' ? (
              <div onClick={() => navigateTo('watch')} className="home-sidebare-item active-sidebare-item" >
                <HomeOutlined style={{ fontSize: 18 }} />
                <div className="mg-l-sidebare bold" >
                  Watch
                </div>
              </div>
            ) : (
              <div onClick={() => navigateTo('home')} className="home-sidebare-item" >
                <HomeOutlined style={{ fontSize: 18 }} />
                <div className="mg-l-sidebare bold" >
                  Watch
                </div>
              </div>
            )} */}
        

            {/* {
          activeRoute === 'livestreaming' ? (
            <div onClick={() => navigateTo('livestreaming')} className="home-sidebare-item active-sidebare-item" >
              <VideoCameraOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Live Streaming
              </div>
            </div>
          ) : (
            <div onClick={() => navigateTo('livestreaming')} className="home-sidebare-item" >
              <VideoCameraOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Live Streaming
              </div>
            </div>
          )} */}
        {
          activeRoute === 'profile' ? (
            <div onClick={() => navigateTo('profile')} className="home-sidebare-item active-sidebare-item"
          >
            <UserOutlined style={{fontSize:18}} />
            <div className="mg-l-sidebare bold" >
              Profile
            </div>
          </div>

          ): (
            <div onClick={() => navigateTo('profile')}  className="home-sidebare-item"
          
          >
            <UserOutlined style={{fontSize:18}} />
            <div className="mg-l-sidebare bold" >
              Profile
            </div>
        </div>

          )
        }

        {
          activeRoute === 'finduser' ? (
            <div onClick={() => navigateTo('finduser')}  className="home-sidebare-item active-sidebare-item" >
              
              <UsergroupDeleteOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Find Users
              </div>
            </div>
          ) : (
            <div onClick={() => navigateTo('finduser')}  className="home-sidebare-item" >
              
              <UsergroupDeleteOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Find Users
              </div>
            </div>
          )}

        {
          activeRoute === 'chat' ? (
        
            <div onClick={() => navigateTo('chat')}  className="home-sidebare-item active-sidebare-item" >
              
              <MessageOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Messenges
              </div>
            </div>
          ) : (
            <div onClick={() => navigateTo('chat')}  className="home-sidebare-item" >
              
              <MessageOutlined style={{fontSize:18}}  />
              <div className="mg-l-sidebare bold" >
                Messenges
              </div>
          </div>   
          )}

        {
          activeRoute === 'famewall' ? (
        
            <div onClick={() => navigateTo('famewall')}  className="home-sidebare-item active-sidebare-item" >
              <UsergroupDeleteOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Famewall
              </div>
            </div>
          ) : (
            <div onClick={() => navigateTo('famewall')}  className="home-sidebare-item" >
              <UsergroupDeleteOutlined style={{ fontSize: 18 }} />
              <div className="mg-l-sidebare bold" >
                Famewall
              </div>
            </div>
              
            )}
        


        </div>

    )

}
export default Sidebare