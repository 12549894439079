import {
  ACTIVE_TAB,
  PAUSED_VIDEO,
  ACTIVE_VIDEO,
} from "../config/reduxvariables";
const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIVE_TAB:
      return { ...state, activeTab: action.index };

    case PAUSED_VIDEO:
      return { ...state, pausedVideo: action.bool };

    case ACTIVE_VIDEO:
      return { ...state, activeVideo: action.info };

    default:
      return { ...state };
  }
};
