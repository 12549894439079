import React, { useState, useContext } from "react";
import { Form, Input, Button, Checkbox, Row, Col, Menu, Dropdown } from "antd";
import { UserOutlined, LockOutlined, DownOutlined } from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { AuthContext } from "../hooks";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";

const Login = (props) => {
  const { t } = useTranslation();

  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { doLogin } = useContext(AuthContext);

  const login = async () => {
    try {
      await doLogin({ email, password });
      history.push("/home");
    } catch (error) {}
  };

  const languageLists = (t) => {
    return [
      { id: Math.random(), text: "English", translateKey: "en" },
      { id: Math.random(), text: "German", translateKey: "de" },
      { id: Math.random(), text: "French", translateKey: "fr" },
      { id: Math.random(), text: "Spanish", translateKey: "sp" },
    ];
  };

  const languageChangeHandler = (value) => {
    const { i18n } = props;
    i18n.changeLanguage(value);
  };

  const menu = (
    <Menu>
      {languageLists(t).map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => languageChangeHandler(item.translateKey)}
        >
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <div style={{ position: "absolute", top: "3%", right: "25%" }}>
        <Dropdown overlay={menu}>
          <p
            className="ant-dropdown-link"
            style={{ fontWeight: "bold", fontSize: ".8vw", cursor: "pointer" }}
            onClick={(e) => e.preventDefault()}
          >
            Select Language <DownOutlined />
          </p>
        </Dropdown>
      </div>
      <Row justify="center">
        <Col lg={8} md={10} sm={16} xs={18} style={{ marginTop: 90 }}>
          <p className="center">
            <img
              width="100"
              height="100"
              style={{ borderRadius: 50 }}
              src={require("../assets/images/icon.jpg")}
              alt="logo"
            />
          </p>
          <p className="center bold f16 black">{t("WELCOME BACK")}</p>
          <Form
            name="normal_login"
            className="width-100 mg-t-20"
            initialValues={{
              remember: true,
            }}
            onFinish={login}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please input your email address!",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("Email")}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("Password")}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>{t("Remember me")}</Checkbox>
              </Form.Item>

              <Link to="/forget-password" className="login-form-forgot" href="">
                {t("Forgot password")}
              </Link>
            </Form.Item>

            <Form.Item>
              <p>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                >
                  {t("Log in")}
                </Button>
              </p>
              <p className="center">Or</p>
              <div>
                <Link to="/signup" className="bold">
                  {t("SIGNUP NOW!")}
                </Link>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default Login;
