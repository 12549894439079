import React, { useEffect, useState } from "react";
import { Layout, Table } from "antd";
import axios from "axios";
import { UserOutlined } from "@ant-design/icons";
import PageHeader from "../components/partials/header";
import { variables } from "../constants/variables";

import { useTranslation } from "react-i18next";

export default function FameWall() {
  const { t } = useTranslation();

  const columns = [
    {
      title: "Photo",
      render: (photo) =>
        photo === null ? (
          <img className="header-photo fit-cover" alt="example" src={photo} />
        ) : (
          <div className="flex flex-row justify-center  items-center header-photo ">
            <UserOutlined style={{ fontSize: "14px" }} />
          </div>
        ),
      key: "photo",
    },
    {
      title: t("First Name"),
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: t("Second Name"),
      dataIndex: "second_name",
      key: "second_name",
    },
    {
      title: t("City"),
      dataIndex: "city",
      key: "city",
    },
    {
      title: t("Gender"),
      key: "gender",
      dataIndex: "gender",
    },
    {
      title: t("Karma"),
      key: "karma",
      dataIndex: "karma",
    },
  ];

  const [famewall, setFamewall] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFamewall();
    return () => {};
  }, []);

  const getFamewall = () => {
    setLoading(true);
    axios
      .get(`${variables.baseUrl}/famewall`)
      .then((res) => {
        setLoading(false);
        setFamewall(res.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          alert(err.response.data.message);
        } else if (err.request) {
          alert("Errorrrr,Please try again or check you internet connection.");
        } else {
          alert("Error,Please try again or check you internet connection.");
        }
      });
  };

  return (
    <Layout>
      <PageHeader />
      {/* <Sidebare /> */}
      <div style={{ overflowX: "scroll" }} className="center-contents-full">
        <Table columns={columns} dataSource={famewall} />
      </div>
    </Layout>
  );
}
