import { service } from "../utils/request";

export function names() {
  return service({
    url: "/names",
    method: "get",
  });
}
export function countries() {
  return service({
    url: "/countries",
    method: "get",
  });
}
export function StatesByCountry(countryId) {
  return service({
    url: `/states-by-country/${countryId}`,
    method: "get",
  });
}

export function citiesByState(stateId) {
  return service({
    url: `/cities-by-state/${stateId}`,
    method: "get",
  });
}

export function getUserDetail(id) {
  return service({
    url: `/user/${id}`,
    method: "get",
  });
}
export function login(data) {
  return service({
    url: "/login",
    method: "post",
    data: data,
  });
}

export function register(data) {
  return service({
    url: "/register",
    method: "post",
    data: data,
  });
}

export function changePassword(data) {
  return service({
    url: "/change-password",
    method: "post",
    data: data,
  });
}

export function resetPasswordRequest(data) {
  return service({
    url: "/reset-password-request",
    method: "put",
    data: data,
  });
}

export function verifyReset(data) {
  return service({
    url: "/verify-reset",
    method: "put",
    data: data,
  });
}

export function resetPassword(data) {
  return service({
    url: "/reset-password",
    method: "put",
    data: data,
  });
}

export function getOTP(data) {
  return service({
    url: "/get-otp",
    method: "post",
    data: data,
  });
}

export function continueFaceBookUser(data) {
  return service({
    url: "/with-facebook",
    method: "post",
    data: data,
  });
}

export function updateProfilePhoto(data) {
  return service({
    url: "/profile-photo",
    method: "put",
    data: data,
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  });
}

export function logout() {
  return service({
    url: "/logout",
    method: "post",
  });
}

export function accountDelete(data) {
  return service({
    url: "/delete_user_account",
    method: "delete",
    data: data,
  });
}

export function blockAccount() {
  return service({
    url: "/block",
    method: "put",
  });
}

export function verifyOTP(data) {
  return service({
    url: "/verify-otp",
    method: "put",
    data: data,
  });
}

export function addFcmToken(data) {
  return service({
    url: "/fcm-token",
    method: "put",
    data: data,
  });
}

export function removeFcmToken(data) {
  return service({
    url: "/fcm-token",
    method: "delete",
    data: data,
  });
}

export function updatePhoto(data) {
  return service({
    url: "/user/photo",
    method: "post",
    data: data,
    config: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}
