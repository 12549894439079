import firebase from "firebase";
export const getFirebaseDeviceToken = async () => {
  let fcmToken = await localStorage.getItem("fcmToken");
  if (!fcmToken) {
    fcmToken = await firebase.messaging().getToken();
    if (fcmToken) {
      await localStorage.setItem("fcmToken", fcmToken);
    }
  }
  return fcmToken;
};
