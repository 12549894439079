import firebase from "firebase";
import { firebaseConfig } from "../src/firebaseconfig";
import Axios from "axios";
import { variables } from "./constants/variables";
export const initializeFirebase = () => {
  firebase.initializeApp(firebaseConfig);
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();

    await messaging.requestPermission();
    messaging.onMessage((payload) => {
      console.log(payload);
    });
  } catch (error) {
    console.error(error);
  }
};

export const subscribeToTopic = (token) => {
  console.log("done");
  Axios.post(`${variables.baseUrl}/subscribe`, {
    token: token,
  })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
      if (err.response) {
        if (err.response.status === 400) alert(err.response.data.message);
      }
    });
};
