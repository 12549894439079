import React, { useState, useContext } from "react";
import { Form, Input, Button, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../hooks";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";

export default function ForgetPassword() {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const { doResetPasswordRequest } = useContext(AuthContext);
  const resetPassword = async () => {
    await doResetPasswordRequest({ email });
  };

  return (
    <Row justify="center">
      <Col lg={8} md={10} sm={16} xs={18} style={{ marginTop: 90 }}>
        <p className="center">
          <img
            width="100"
            height="100"
            style={{ borderRadius: 50 }}
            src={require("../assets/images/icon.jpg")}
            alt="logo"
          />
        </p>

        <Form
          name="normal_login"
          className="width-100 mg-t-20"
          initialValues={{
            remember: true,
          }}
          onFinish={resetPassword}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: t("Please input your email address!"),
              },
            ]}
          >
            <Input
              size="large"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t("Email")}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <p>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Proceed")}
              </Button>
            </p>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}
