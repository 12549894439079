import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

import { createStore } from "redux";
import { Provider } from "react-redux";
import Reducer from "./reducers/index";

import "./index.css";
import "./assets/styles/login.scss";
import "./assets/styles/home.scss";
import "antd/dist/antd.css";

import Home from "./pages/home";
import Login from "./pages/login";
import ForgetPassword from "./pages/forgetpassword";
import Signup from "./pages/signup";
import Splash from "./pages/splash";
import Profile from "./pages/profile";
import UserProfile from "./pages/userProfile";

import { register } from "./serviceWorker";
import Chat from "./pages/chat";
import FameWall from "./pages/famewall";
import { CallProvider } from "./context";
import {
  AppProvider,
  AuthProvider,
  ChatProvider,
  LoaderProvider,
  PostProvider,
  ProfileProvider,
  ToastProvider,
} from "./hooks/index";
import FindUser from "./pages/finduser";
import Streaming from "./pages/streaming";
import Menu from "./pages/menu/index";
// import Call from './components/call/call';
import App from "./App.js";
import {
  initializeFirebase,
  askForPermissioToReceiveNotifications,
} from "./push-notification";
import ResetPassword from "./pages/resetpassword";
import { AuthGuard } from "./guards/AuthGuard";

// ============== Trnaslation ============== //
import i18nExecute from "./helper/i18next/i18next";

//export const stripeKey = loadStripe("pk_test_bdU8iLjvjSjtdYl4TwcF4uFC"); //Test From Saeed Dev Account
export const stripeKey = loadStripe("pk_live_51J7gU1JNbeMOR58tDk994c4JyaGS9gSaZDdBQdjCXkWXvgj01KBOlC8ZoXbPxIJukxKaudv9dhB88rSwE3ulgQa7005FjbZigO"); //Live From Client Account
export const store = createStore(Reducer);

class Wrapper extends React.Component {
  async componentDidMount() {}

  render() {
    return (
      <Provider store={store}>
        <LoaderProvider>
          <ToastProvider>
            <AppProvider>
              <CallProvider>
                <ProfileProvider>
                  <AuthProvider>
                    <ChatProvider>
                      <PostProvider>
                        {/* <Call /> */}
                        <Router basename={'/'}>
                          <div>
                            <Switch>
                              <Route path="/login">
                                <Login i18n={i18nExecute} />
                              </Route>
                              <Route path="/forget-password">
                                <ForgetPassword />
                              </Route>
                              <Route path="/signup">
                                <Signup i18n={i18nExecute} />
                              </Route>
                              <Route path="/profile">
                                <AuthGuard>
                                  <Profile i18n={i18nExecute} />
                                </AuthGuard>
                              </Route>
                              <Route path="/user/:id">
                                <UserProfile i18n={i18nExecute} />
                              </Route>
                              <Route path="/home">
                                <Home i18n={i18nExecute} />
                              </Route>
                              <Route path="/livestreaming">
                                <Streaming i18n={i18nExecute} />
                              </Route>
                              <Route path="/reset-password">
                                <ResetPassword i18n={i18nExecute} />
                              </Route>
                              <Route path="/finduser">
                                <FindUser i18n={i18nExecute} />
                              </Route>

                              <Route path="/famewall">
                                <FameWall i18n={i18nExecute} />
                              </Route>

                              <Route path="/chat">
                                <Chat i18n={i18nExecute} />
                              </Route>
                              <Route path="/menu">
                                <Menu i18n={i18nExecute} />
                              </Route>
                              {/* <Route path="/calling">
                              <Calling />
            </Route> */}

                              <Route path="/">
                                <Splash i18n={i18nExecute} />
                              </Route>
                            </Switch>
                          </div>
                        </Router>
                      </PostProvider>
                    </ChatProvider>
                  </AuthProvider>
                </ProfileProvider>
              </CallProvider>
            </AppProvider>
          </ToastProvider>
        </LoaderProvider>
      </Provider>
    );
  }
}

ReactDOM.render(
  <Wrapper />,
  // <App />

  document.getElementById("root")
);
register();
// serviceWorker.register();
initializeFirebase();
askForPermissioToReceiveNotifications();
