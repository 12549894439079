import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Form,
  Input,
  Card,
  Tooltip,
  Button,
  Carousel,
  Row,
  Col,
  Avatar,
  Comment,
} from "antd";
import moment from "moment";
import ReactPlayer from "react-player";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import VisibilitySensor from "react-visibility-sensor";
import { PostContext, ProfileContext } from "../../hooks/providers";
import "./styles.scss";
const ItemModal = ({ pst, t }) => {
  const [likeloader, setLikeloader] = useState(false);
  const [showComments, setShowComments] = useState(true);
  const [likes, setLikes] = useState(0);
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState("");
  const {
    doLikePost,
    doGetPost,
    doUnLikePost,
    doAddComment,
    increaseCommentsCountOnPost,
  } = useContext(PostContext);
  const { likesList, setLikesList } = useContext(ProfileContext);

  useEffect(() => {
    setLikes(pst.likes);
    return () => {};
  }, [pst.likes]);

  useEffect(() => {
    setPost({ ...pst, comments: [] });
  }, [pst]);

  const fetchPost = useCallback(
    async (id) => {
      const pst1 = await doGetPost(id);
      setPost(pst1);
    },
    [doGetPost]
  );

  useEffect(() => {
    (async () => {
      if (pst._id) {
        fetchPost(pst._id);
      }
    })();
  }, [doGetPost, fetchPost, pst._id]);

  const addComment = () => {
    setShowComments(!showComments);
  };

  const likeUnlikePost = async (id, action) => {
    setLikeloader(true);
    if (action === "like") {
      setLikeloader(false);
      setLikes(likes + 1);
      setLikesList([...likesList, id]);
      await doLikePost(id);
    } else {
      setLikeloader(false);
      setLikes(likes - 1);
      let liks = [...likesList];
      liks.splice(likesList.indexOf(id), 1);
      setLikesList([...liks]);
      await doUnLikePost(id);
    }
  };

  const isFavorate = (post_id, likeloader) => {
    if (likeloader) {
      return <Button loading={true}>{t("Like")}</Button>;
    } else {
      if (likesList.includes(post_id)) {
        return (
          <Button
            onClick={() => likeUnlikePost(post_id, "unlike")}
            type="primary"
          >
            {t("Like")}
          </Button>
        );
      } else {
        return (
          <Button
            onClick={() => likeUnlikePost(post_id, "like")}
            type="default"
          >
            {t("Like")}
          </Button>
        );
      }
    }
  };

  const sendComment = useCallback(async () => {
    console.log(post);
    if (comment.length > 0) {
      try {
        const cmnt = await doAddComment({ id: post._id, comment });
        setPost({ ...post, comments: [...post.comments, cmnt] });
        await increaseCommentsCountOnPost(post._id, {
          text: comment,
          id: post._id,
        });
        setComment("");
      } catch (error) {}
    }
  }, [comment, doAddComment, increaseCommentsCountOnPost, post]);

  return (
    <>
      {post && (
        <Card
          style={{ width: "100%", marginBottom: 10 }}
          cover={
            <div>
              <div className="post-header pd-15">
                <div className="flex flex-row items-center ">
                  {post?.user?.photo ? (
                    <img
                      className="post-logo fit-cover"
                      alt="example"
                      src={post?.user?.photo}
                    />
                  ) : (
                    <div
                      className="flex flex-row justify-center  items-center"
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 15,
                        backgroundColor: "lightgrey",
                      }}
                    >
                      <UserOutlined style={{ fontSize: "14px" }} />
                    </div>
                  )}
                  <div className="mg-l-10">
                    {post?.user.first_name}
                    {post?.user.second_name}
                  </div>
                </div>

                <div className="mg-t-10">{post.text}</div>
              </div>
              <div>
                {post.photos.length > 0 ? (
                  <Carousel autoplay={true}>
                    {post.photos.map((ph) => {
                      return (
                        <div key={ph._id}>
                          <img width="100%" alt="example" src={ph.url} />
                        </div>
                      );
                    })}
                  </Carousel>
                ) : (
                  <div></div>
                )}
                {post.videos && post.videos.length > 0 && (
                  <VisibilitySensor scrollDelay={500}>
                    {({ isVisible }) => (
                      <ReactPlayer
                        controls
                        className="video-player"
                        playing={isVisible ? true : false}
                        width="100%"
                        url={post.videos[0].url}
                      />
                    )}
                  </VisibilitySensor>
                )}
              </div>
            </div>
          }
        >
          <div className="mb-10">
            <div className="flex flex-row justify-between">
              <Button type="text">
                {likes} {t("Likes")}
              </Button>
              <Button type="text">
                {post.comments.length} {t("Comments")}
              </Button>
            </div>
            <div className="mg-t-10">
              {isFavorate(post._id, likeloader)}
              <Button onClick={() => addComment()} className="mg-l-10">
                {t("Comments")}
              </Button>
            </div>
          </div>

          {showComments && (
            <>
              <div className="comments-wrapper">
                {post?.comments?.map((comment) => {
                  return (
                    <Comment
                      author={
                        <a>
                          {comment.user.first_name} {comment.user.last_name}
                        </a>
                      }
                      avatar={
                        <Avatar
                          src={comment.user.photo}
                          alt={comment.user.first_name}
                        />
                      }
                      content={<p>{comment.text}</p>}
                      datetime={
                        <Tooltip
                          title={moment(comment.createdAt).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )}
                        >
                          <span>{moment().fromNow()}</span>
                        </Tooltip>
                      }
                    />
                  );
                })}
              </div>

              <Form>
                <Row>
                  <Col flex="auto">
                    <Input
                      placeholder={t("Write")}
                      name="comment"
                      type="text"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                  </Col>
                  <Col className="ml-10">
                    <Button
                      type="primary"
                      onClick={sendComment}
                      icon={<PlusOutlined />}
                    >
                      {t("Send")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default ItemModal;
