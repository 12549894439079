import React, { useState } from "react";
import { Modal, Select, Tooltip, Button, Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { env } from "../../assets/env";
import { openAlert } from "../../service/notification";
import { variables } from "../../constants/variables";
const { Option } = Select;
const PicturesWall = ({ t }) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const [visibility, setVisibility] = useState("public");
  const [text, setText] = useState("");
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    for (const file of photos) {
      var img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = function () {
        file.width = this.width;
        file.height = this.height;
      };
    }
    addPost();
  };

  const handleCancel = () => {
    setVisible(false);
    setConfirmLoading(false);
  };

  const handleCancelChild = () => setPreviewVisible(true);

  const handlePreview = async (name, url) => {
    setPreviewImage(url);
    setPreviewVisible(true);
    setPreviewTitle(name);
  };

  const openFile = (event) => {
    setPhotos(Array.from(event.target.files));
  };

  const addPost = async () => {
    if (photos.length === 0 && videos.length === 0 && text.length < 10) {
      if (text.length < 10 && text.length > 0) {
        openAlert(
          t("error"),
          t("Error"),
          t("Minunum 100 letters are required in Text field.")
        );
      } else {
        openAlert(
          t("error"),
          t("Error"),
          t("Please write or add something in the post.")
        );
      }
    } else {
      let tkn = localStorage.getItem("token");
      setConfirmLoading(true);
      const formdata = new FormData();
      let photosSize = [];
      // formdata.app
      for (const p of photos) {
        photosSize.push({ name: p.name, width: p.width, height: p.height });
        formdata.append("photo", p, p.name);
      }
      if (photos.length >= 1) {
        formdata.append("photosSize", JSON.stringify(photosSize));
      }

      // for (const v of videos) {
      //   let videoSize = [];
      //   videoSize.push({
      //     duration: duration,
      //     orientation: orientation,
      //     height: height,
      //     width: width,
      //   });
      //   formdata.append('videosSize', JSON.stringify(videoSize));
      //   formdata.append('video', {
      //     uri: v.uri,
      //     name: v.name,
      //     type: v.type,
      //   });
      // }

      formdata.append("text", text);
      formdata.append("visibility", visibility);
      setConfirmLoading(true);
      axios
        .post(`${variables.baseUrl}/post`, formdata, {
          headers: {
            Authorization: "Bearer " + tkn,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader(
                  "x-decompressed-content-length"
                );
            if (totalLength !== null) {
              // progressData = Math.round(
              //   (progressEvent.loaded * 100) / totalLength
              // );
            }
          },
        })
        .then((res) => {
          setConfirmLoading(false);
          openAlert("success", "Success", "Post Saved");
          setTimeout(() => {
            setVisible(false);
            setPhotos([]);
            setVideos([]);
            setText("");
          }, 1000);
        })
        .catch((err) => {
          setConfirmLoading(false);
          if (err.response) {
            alert("e", err.response.data.message);
          } else if (err.request) {
            openAlert(
              t("error"),
              t("Error"),
              t(
                "Network Error,Please try again or check you internet connection."
              )
            );
          } else {
            openAlert(
              t("error"),
              t("Error"),
              t("Error,Please try again or check you internet connection.")
            );
          }
        });
    }
  };
  return (
    <>
      {/* <Modal
        okText={'Upload'}
        title="Add New Post"
        visible={this.state.visible}
        onOk={this.handleOk}
        confirmLoading={this.confirmLoading}
        onCancel={this.handleCancel}
      >
      <AddPostModal  />
      </Modal> */}

      <Tooltip title={t("Add Post")}>
        <Button
          onClick={() => showModal()}
          className="header-button"
          icon={<PlusOutlined />}
        />
      </Tooltip>

      <Modal
        okText={"Upload"}
        title="Add New Post"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="clearfix">
          <div>
            <label>{t("Visibility")}</label>
          </div>
          <Select
            defaultValue={visibility}
            style={{ width: 120 }}
            onChange={(v) => {
              setVisibility(v);
            }}
          >
            <Option value="public">{t("Public")}</Option>
            <Option value="private">{t("Private")}</Option>
            <Option value="friends">{t("Friends")}</Option>
          </Select>

          <div className="mg-t-20">
            <Input.TextArea
              name="text"
              value={text}
              rows={4}
              placeholder={t("What you want to share ?")}
              onChange={(e) => setText(e.target.value)}
            />
          </div>

          <div className="mg-t-20">{t("Add Photos")}</div>
          <div className="posts-photos-wrapper">
            {photos.map((f) => {
              return (
                <div
                  className=""
                  key={f.name}
                  onClick={() => handlePreview(f.name, URL.createObjectURL(f))}
                >
                  <img
                    className="posts-photo"
                    src={URL.createObjectURL(f)}
                    alt="Not Found"
                  />
                </div>
              );
            })}

            <div>
              <label htmlFor="file-upload" className="post-photo-select">
                <PlusOutlined />
                {t("Upload")}
              </label>
              <input
                accept="image/*"
                multiple={true}
                onChange={(event) => openFile(event)}
                id="file-upload"
                type="file"
              />
            </div>
          </div>
          {/* <div>Add Videos</div> */}

          <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancelChild}
          >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
          </Modal>
        </div>
      </Modal>
    </>
  );
};

export default PicturesWall;
