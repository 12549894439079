import React from "react";
import {
  Card,
  Menu,
  Layout,
  Button,
  Table,
  Spin,
  List,
  Typography,
  Divider,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";

import PageHeader from "../../components/partials/header";

// ============== Trnaslation ============== //
import { withTranslation } from "react-i18next";

const data = [
  { title: "Add Post" },
  { title: "Notification" },
  { title: "Logout" },
];
class FameWall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refresh: false,
      posts: [],
      friends: [],
    };
  }
  navigateTo(nav) {
    this.props.history.push(nav);
  }
  logout() {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  }

  render() {
    const { t } = this.props;

    return (
      <Layout>
        <PageHeader />
        {/* <Sidebare /> */}
        <Spin spinning={this.state.loading}>
          <div className="home-center-contents">
            <Button block onClick={() => this.logout()}>
              {t("Logout")}
            </Button>
          </div>
        </Spin>
      </Layout>
    );
  }
}

export default withTranslation("translations")(withRouter(FameWall));
