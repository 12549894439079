import React from "react";
import { Input, Tooltip, Button } from "antd";
import { connect } from "react-redux";
import { addProfile } from "../../../actions/profile";
import { UserOutlined, PoweroffOutlined } from "@ant-design/icons";
import AddPostModal from "../../addpost";
import { withRouter } from "react-router-dom";
import Notifications from "../../notifications";
import CenterNavigation from "../../header/navigations";
// ===================== Languages ================= //
import { withTranslation } from "react-i18next";

class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
    };
  }

  addPostModel() {}

  logout() {
    localStorage.removeItem("token");
    this.props.history.push("/login");
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    alert("saveed");
    this.setState({
      visible: false,
      confirmLoading: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      confirmLoading: false,
    });
  };

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
    this.props.history.push(e.key);
  };
  render() {
    const { t } = this.props;

    return (
      <div>
        {/* <div className="flex flex-row justify-between top-header" >
        <div className="bold">myVisions</div>
        <div><SearchOutlined /></div>
      </div> */}

        <div className="flex flex-row justify-between header">
          <div className="flex justify-center items-center header-home-button ">
            {this.props.profile.pic ? (
              <img
                className="header-photo"
                src={this.props.profile.pic}
                alt="AppLogo"
              />
            ) : (
              <div className="flex flex-row justify-center  items-center header-photo ">
                <UserOutlined style={{ fontSize: "15px" }} />
              </div>
            )}
            <div className="mg-l-10">{t("myVisions")}</div>
            <div className="mg-l-10"></div>
          </div>

          <CenterNavigation t={t} />

          <div className="header-right-wrapper">
            <AddPostModal t={t} />
            <Notifications t={t} />
            <Tooltip title={t("Logout")}>
              <Button
                onClick={() => this.logout()}
                className="header-button"
                icon={<PoweroffOutlined />}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}
const mapStatesToProps = (state) => {
  return {
    profile: state.profile,
    config: state.config,
  };
};
export default withTranslation("translations")(
  connect(mapStatesToProps, { addProfile })(withRouter(PageHeader))
);
