import React, { useContext, useState, useEffect } from "react";
import { Card, Tabs, Button, Tooltip, Layout, Row, Col, Carousel } from "antd";
import ReactPlayer from "react-player";
import PageHeader from "../components/partials/header";
import { UserOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { AuthContext, PostContext, ProfileContext } from "../hooks";
import { t } from "i18next";
const { TabPane } = Tabs;

export default function UserProfile() {
  const [profile, setProfile] = useState(null);
  const [friends, setFriends] = useState([]);

  const { doGetUserFriends } = useContext(ProfileContext);

  const { doGetUser } = useContext(AuthContext);
  const { id } = useParams();
  const { posts, doGetUserPosts } = useContext(PostContext);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = async () => {
    try {
      let profile = await doGetUser(id);
      const frnds = await doGetUserFriends(profile._id);
      setProfile(profile);
      setFriends(frnds);
      await doGetUserPosts(profile._id);
    } catch (error) {}
  };
  return (
    <Layout>
      <PageHeader />
      {profile && (
        <div className="center-contents-profile">
          <div className="profile-page-photo-wrapper">
            {profile && profile.photo ? (
              <img
                className="profile-page-photo"
                src={profile.photo}
                alt="Profile Pic"
              />
            ) : (
              <div className="flex flex-row justify-center items-center profile-page-photo">
                <UserOutlined style={{ color: "white", fontSize: "25px" }} />
              </div>
            )}
          </div>

          <div className="flex justify-center items-center mg-t-30">
            <center>
              <div className="f16 bold">
                {profile.first_name} {profile.second_name}
              </div>
              <div>{profile.status}</div>
            </center>
          </div>
          <Tabs className="mh-t-30" defaultActiveKey="1" centered>
            <TabPane tab="About" key="1">
              <div className="center-contents-profile-posts">
                <Card className="mg-t-20">
                  <div className="flex justify-between ">
                    <div className="bold">{t("First Name")}</div>
                    <div>{profile.first_name}</div>
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold">{t("Second Name")}</div>
                    <div>{profile.second_name}</div>
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold">{t("Country")}</div>
                    <div>{profile.country}</div>
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold">{t("State")}</div>
                    <div>{profile.state}</div>
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold">{t("City")}</div>
                    <div>{profile.city}</div>
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold">{t("Gender")}</div>
                    <div>{profile.gender}</div>
                  </div>
                </Card>
              </div>
            </TabPane>
            <TabPane tab="Friends" key="2">
              <div className="search-user-wrapper">
                {friends.map((u) => {
                  return (
                    <Card className="mg-t-20 pointer  bg w-50 ">
                      <div className="flex items-center justify-between ">
                        <div className="flex items-center">
                          {u.user.photo ? (
                            <img
                              className="header-photo fit-cover"
                              alt="example"
                              src={u.user.photo}
                            />
                          ) : (
                            <div className="flex flex-row justify-center  items-center header-photo ">
                              <UserOutlined style={{ fontSize: "14px" }} />
                            </div>
                          )}
                          <div className="mg-l-20">
                            <div className="bold">
                              {" "}
                              {u.user.first_name} {u.user.second_name}{" "}
                            </div>
                            <div className="dim">
                              {u.user.city}, {u.user.country}{" "}
                            </div>
                          </div>
                        </div>
                        <Button type="primary" icon={<CheckOutlined />}>
                          {t("FRIEND")}
                        </Button>
                      </div>
                    </Card>
                  );
                })}
                {friends.length === 0 && (
                  <center>
                    <h3>{t("Not have any friend")}</h3>
                  </center>
                )}
              </div>
            </TabPane>
            <TabPane tab="Posts" key="4">
              <div className="center-contents-profile-posts">
                {posts.map((p) => {
                  return (
                    <Card
                      key={p._id}
                      style={{ width: "100%", marginBottom: 10 }}
                      cover={
                        <div>
                          <div className="post-header pd-15">
                            <div className="flex flex-row items-center ">
                              {p.user.photo ? (
                                <img
                                  className="post-logo fit-cover"
                                  alt="example"
                                  src={p.user.photo}
                                />
                              ) : (
                                <div
                                  className="flex flex-row justify-center  items-center"
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    backgroundColor: "lightgrey",
                                  }}
                                >
                                  <UserOutlined style={{ fontSize: "14px" }} />
                                </div>
                              )}
                              <div className="mg-l-10">
                                {p.user && p.user.first_name}{" "}
                                {p.user && p.user.second_name}{" "}
                              </div>
                            </div>

                            <div className="mg-t-10">{p.text}</div>
                          </div>
                          <div>
                            {p.photos.length > 0 ? (
                              <Carousel>
                                {p.photos.map((ph) => {
                                  return (
                                    <div key={ph._id}>
                                      <img
                                        width="100%"
                                        alt="example"
                                        src={ph.url}
                                      />
                                    </div>
                                  );
                                })}
                              </Carousel>
                            ) : (
                              <div></div>
                            )}
                            {p.videos && p.videos.length > 0 && (
                              <ReactPlayer
                                className="video-player"
                                playing={false}
                                width="100%"
                                url={p.videos[0].url}
                              />
                            )}
                          </div>
                        </div>
                      }
                    >
                      <div>
                        <div className="flex flex-row justify-between">
                          <div>
                            {p.likes} {t("Likes")}
                          </div>
                          <div>
                            {p.comments} {t("Comments")}
                          </div>
                        </div>
                        <div className="mg-t-10">
                          {/* {this.isFavorate(
                                this.profile.likes,
                                p._id,
                                likeloader,
                              )} */}
                          <Button className="mg-l-10">{t("Comment")}</Button>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </TabPane>
          </Tabs>
        </div>
      )}
    </Layout>
  );
}
