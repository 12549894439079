// // remove track but call not connected
// import React, { useState, useRef, useEffect } from "react";
// import io from 'socket.io-client';
// import { env } from "../assets/env";
// import {Tooltip, Button } from 'antd';
// import sound from '../assets/sounds/notification.mp3'
// import sound_soft from '../assets/sounds/soft_notification.mp3'
// import call_in from '../assets/sounds/call_in.mp3'
// import call_out from '../assets/sounds/call_out.mp3'
// import snd from '../assets/sounds/soft_notification.mp3'
// // import snd from '../assets/sounds/me-too.m4r'
// import  { notification } from 'antd'
// import {VideoCameraOutlined,  PhoneOutlined } from '@ant-design/icons';
// import { openAlert } from "../service/notification";
// let pc, sender;
// export const CallContext = React.createContext("home");

//  // {
//       //   urls: 'stun:[STUN_IP]:[PORT]',
//       //   'credentials': '[YOR CREDENTIALS]',
//       //   'username': '[USERNAME]'
//       // },

// export const CallProvider = ({ children }) => {
//   let url = window.location.pathname;

//   const [audio] = useState(new Audio(sound));
//   const [callIn] = useState(new Audio(call_in));
//   const [callOut] = useState(new Audio(call_out));
//   const [playing, setPlaying] = useState(false);
//   const [callAudioPlaying, setCallAudioPlaying] = useState(false);
//   // const [pc, setPc] = useState(null)
//   // const [sender, setSender] = useState(null)

//     const [activeRoute, setActiveRoute] = useState(url.replace('/', ''));
//     const [socket, setSocket] = useState(null);

//     const [messages, setMessages] = useState([])
//     const [sdp, setSdp] = useState(null)
//     const [candidate, setCandidate] = useState(null)

//     const [receivingCall, setReceivingCall] = useState(false)
//     const [callerSignal, setCallerSignal] = useState();
//     const [yourSocketID, setYourSocketID] = useState(null);

//     const [makingCall, setMakingCall] = useState(false);
//     const [isVideo, setIsVideo] = useState(true)
//     const [isAudio, setIsAudio] = useState(true)
//     const [notifications, setNotifications] = useState([])

//     const toggle = () => setPlaying(!playing);

//     const pc_config = {
//       iceServers: [
//         {
//           urls: 'stun:stun.l.google.com:19302',
//         }, {
//           urls: 'stun:stun1.l.google.com:19302',
//         }, {
//           urls: 'stun:stun2.l.google.com:19302',
//         }

//       ],
//     }

//       const [callerID, setCallerID] = useState('')
//       const [visible, setVisible] = useState('none')
//       const [receiverID, setReceiverID] = useState('')
//       // const [pc, setPc] = useState(null)
//       const [callActive, setCallActive] = useState(false)
//       const [askingForCall, setAskingForCall] = useState(false)
//       const [incommingCall, setIncommingCall] = useState(false)
//       // const [askingForCall, setAskingForCall] = useState(false)

//       const localVideoref = useRef()
//       const remoteVideoref = useRef()

//   useEffect(() => {
//     // startMedia()
//     // let pic = new RTCPeerConnection(pc_config)
//     // let pic = new RTCPeerConnection(pc_config)
//     // setPc(pic)
//   },[])

//   useEffect(() => {
//     let socket = io(variables.baseUrl, {
//         query: {
//             token: localStorage.getItem('token'),
//             id: localStorage.getItem('id')
//         }
//     })
//     setSocket(socket);
//   }, [])

//   useEffect(() => {
//     if (socket) {
//       subscribeForMessages()
//     }
//   }, [socket,pc]);

//   useEffect(() => {
//     if (socket) {
//         // createConnectionForPeer()
//     }
//   },[socket,receiverID,callerID])

//   const subscribeForMessages = () => {
//     socket.on('message', msg => {
//       addMessage(msg)
//       if (activeRoute !== 'chat') {
//         notification['info']({
//           message: msg.text,
//           // description:msg.text,
//           placement: 'bottomRight'
//         });
//       }
//     })
//     socket.on('askForCall', data => {
//         pc = new RTCPeerConnection(pc_config)
//         callIn.loop = true;
//         callIn.play()
//         setVisible('')
//         setIncommingCall(true)
//         setCallerID(data.callerID)
//         setSdp(data.sdp)
//     })
//     socket.on('candidate', (data) => {
//       // alert('candidate received')
//       setCandidate(data.candidate)
//       if (pc) {
//         // alert('candidate request')/
//         pc.addIceCandidate(new RTCIceCandidate(data.candidate))
//       }
//     })
//   }

//   const setCandidateID = () => {
//     // alert('candidate added')
//     if (candidate) {
//       pc.addIceCandidate(new RTCIceCandidate(candidate))
//     }

//   }

//   const setRemoteID = () => {
//     console.log(sdp)
//     pc.setRemoteDescription(new RTCSessionDescription(sdp))

//   }

//   const createConnectionForPeer = () => {
//     if (pc) {
//       console.log(pc)
//       socket.on('pickCall', data => {
//         callOut.pause()
//         // if (candidate) {
//           // setCandidateID()
//         // }
//         setAskingForCall(false)
//         pc.setRemoteDescription(new RTCSessionDescription(data.sdp))
//       })

//       socket.on('closeCall', () => {
//         setVisible('none')
//         pc.close();
//         stopStreamedVideo(localVideoref);
//         // pc.removeTrack(sender)
//         setTimeout(() => {
//           // pc.restartIce();
//              // let pic = null
//           // setPc(pic)
//           // setTimeout(() => {
//             // let pi = new RTCPeerConnection(pc_config)
//             // setPc(pi)
//             pc = null;
//           // }, 1000);
//           // createConnectionForPeer()
//         }, 1000);
//       })

//       pc.onicecandidate = (e) => {
//         console.log(e.candidate)
//         // alert('ice candidate')
//         if (e.candidate) {
//           // alert('emitted from this side')
//           socket.emit('candidate', { candidate: e.candidate, callerID: socket.id, receiverID: receiverID })
//         }
//       }

//       pc.oniceconnectionstatechange = (e) => {
//         console.log(e)
//       }
//       // pc.onaddstream = (e) => {
//       // remoteVideoref.current.srcObject = e.stream
//       // }
//       pc.ontrack = (e) => {
//         console.log(e)
//         remoteVideoref.current.srcObject = e.streams[0]
//       }
//       pc.onconnectionstatechange = (e) => {
//         switch (pc.connectionState) {
//           case "connected":
//             console.log('CONNECTED')
//             // The connection has become fully connected
//             break;
//           case "disconnected":
//             console.log('DISCONNECTED')

//           case "failed":
//             // One or more transports has terminated unexpectedly or in an error
//             console.log('FAIL')
//             break;
//           case "closed":
//             console.log('CLOSE')
//             // The connection has been closed
//             break;
//         }
//       }
//     }
//     // else {
//       // let pic = null;
//       // setPc(pic)
//     // }
//   }
//   const startMedia = (id,TYPE) => {
//     pc = new RTCPeerConnection(pc_config)

//     const success = (stream) => {
//       setTimeout(() => {
//         createConnectionForPeer();
//         window.localStream = stream
//         localVideoref.current.srcObject = stream
//         // var track = stream.getVideoTracks()[0];
//         // sender = pc.addTrack(track, stream);
//         pc.addStream(stream)
//         if (TYPE === 'caller') {
//           askForCall(id)
//         }
//         else if (TYPE === 'receiver') {
//           // alert('call picked')
//           pickCall()
//         }
//       }, 1000);
//     }
//     // called when getUserMedia() fails - see below
//     const failure = (e) => {
//       console.log('getUserMedia Error: ', e)
//     }

//     const constraints = {
//       audio: isAudio,
//       video: isVideo,
//       // video: {
//       //   width: 1280,
//       //   height: 720
//       // },
//       // video: {
//       //   width: { min: 1280 },
//       // }
//       options: {
//         mirror: false,
//       }
//     }
//     // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
//     navigator.mediaDevices.getUserMedia(constraints)
//       .then(success)
//       .catch(failure)
//   }

//   const addMessage = (newMessage) => {
//     setMessages(state => [...state, newMessage])
//     if (newMessage.to === localStorage.getItem('id')) {
//       audio.volume = 0.2;
//       audio.play()

//     }
//   }

//   /* ACTION METHODS FROM THE BUTTONS ON SCREEN */

//   const askForCall = (id) => {
//       pc.createOffer({ offerToReceiveVideo: 1 })
//         .then(sdp => {
//           pc.setLocalDescription(sdp)
//           setReceiverID(id)
//           setCallerID(socket.id)
//           setVisible('')
//           setIsAudio(true)
//           setAskingForCall(true)
//           console.log(id)
//           console.log(socket.id)
//           console.log(sdp)
//           socket.emit('askForCall', { receiverID: id, callerID: socket.id, sdp: sdp })
//           callOut.loop = true;
//       })
//   }

//   const pickCall = () => {
//       setRemoteID()
//       // setCandidateID()
//       callIn.pause()
//       pc.createAnswer({ offerToReceiveVideo: 1 })
//         .then(sdp => {
//         console.log(sdp)
//         console.log(callerID)
//         pc.setLocalDescription(sdp)
//         setIncommingCall(false)
//         socket.emit('pickCall', {sdp:sdp, callerID: callerID} )
//       })
//   }

//   const stopStreamedVideo = (videoElem)  => {
//     const stream = localVideoref.current.srcObject
//     const tracks = stream.getTracks();

//     tracks.forEach(function(track) {
//       track.stop();
//     });

//     localVideoref.current.srcObject = null;
//   }

//   const closeCall = () => {
//     let users = []
//     if(callerID){
//       users.push(callerID)
//     }
//     if(receiverID){
//       users.push(receiverID)
//     }
//     socket.emit('closeCall', { callerID: callerID, receiverID: receiverID } )
//   }

//   const updateActiveRoute = (route) => {
//       setActiveRoute(route);
//   };

//     return (
//         <CallContext.Provider value={{messages, setMessages , socket, callerID, receiverID ,askForCall, startMedia , pickCall, makingCall, receivingCall, callerID, callerSignal , yourSocketID, activeRoute, updateActiveRoute, notifications, setNotifications }}>
//            <>
//            <div className="call-wrapper"
//               style={{ display: visible }}
//             >
//           <div className="call-wrapper-top" >

//             <div className="floating-video" >
//               {/* {UserVideo} */}
//               <video
//               className="user-video"
//                 ref={ localVideoref }
//                 autoPlay muted>
//               </video>
//             </div>
//               <div className="flex items-center justify-center"  >
//                   <h2 className="white center mg-0" >
//                       Muhammad Sarmad
//                   </h2>
//                   {/* <div className="white center mg-l-20 " >
//                     02:05
//                   </div>   */}
//                   {/* <div className="white center mg-l-20 " >
//                     Ringing
//                   </div>   */}
//               </div>

//             </div>

//             <div className="call-wrapper-center" >
//             {
//               askingForCall && (
//                 <h3 className="center white" >Calling...</h3>
//               )
//             }
//             {
//               incommingCall &&  (
//               <div className="center" >
//                   <h3 className="center white" >Incomming Call...</h3>
//                   <Button size="large" type="primary"  onClick={ () => startMedia(callerID,'receiver') }>Pick Call</Button>
//               </div>
//               )
//             }
//           {/* { startCalling === true && callAccepted !== true ? (
//                <h1 className="white" >Calling...</h1>
//           ) : startCalling === false && callAccepted !== true && receivingCall ? (
//                 incomingCall
//             ) : (
//               PartnerVideo
//               )
//               } */}
//             </div>
//             {/* <div className="call-wrapper-center" >
//             callinf
//               </div> */}
//                     {
//           // Object.keys(users).map(k => {
//           //   if (k !== socket.id) {
//           //     return (
//           //       <button onClick={ () => askForCall(k) }>Call {k}</button>
//           //     )
//           //   }
//           // })
//         }

//             <video
//               className="partner-video"
//               ref={ remoteVideoref }
//               autoPlay>
//             </video>

//         <div className="call-wrapper-bottom" >

//           {/* {remoteVideoref ? (
//             <Tooltip title="Stop Video">
//               <Button
//               // onClick={()=> setVideo(false)}
//                type="primary" className="mg-l-20" danger shape="circle" icon={<VideoCameraOutlined />} />
//             </Tooltip>
//             ): (

//             <Tooltip title="Start Video">
//               <Button
//               //  onClick={()=> setVideo(true)}
//                className="header-button mg-l-20" icon={<VideoCameraOutlined />} />
//             </Tooltip>
//             )
//           } */}

//               <Tooltip title="Close Call">
//                 <Button type="primary"
//                 onClick={()=> closeCall() }
//                 className="mg-l-20" danger shape="circle" icon={<PhoneOutlined />} ></Button>
//               </Tooltip>
//             </div>

//         </div>

//             {children}
//             </>
//         </CallContext.Provider>
//     )
// }
import React, { useState, useRef, useEffect } from "react";
import io from "socket.io-client";
import { env } from "../assets/env";
import { Tooltip, Button } from "antd";
import sound from "../assets/sounds/notification.mp3";
import sound_soft from "../assets/sounds/soft_notification.mp3";
import call_in from "../assets/sounds/call_in.mp3";
import call_out from "../assets/sounds/call_out.mp3";
import snd from "../assets/sounds/soft_notification.mp3";
// import snd from '../assets/sounds/me-too.m4r'
import { notification } from "antd";
import { VideoCameraOutlined, PhoneOutlined } from "@ant-design/icons";
import { openAlert } from "../service/notification";
import { variables } from "../constants/variables";

let pc;
export const CallContext = React.createContext("home");

export const CallProvider = ({ children }) => {
  let url = window.location.pathname;

  const [audio] = useState(new Audio(sound));
  const [callIn] = useState(new Audio(call_in));
  const [callOut] = useState(new Audio(call_out));
  const [playing, setPlaying] = useState(false);
  const [callAudioPlaying, setCallAudioPlaying] = useState(false);
  // const [pc, setPc] = useState(null)

  const [activeRoute, setActiveRoute] = useState(url.replace("/", ""));
  const [socket, setSocket] = useState(null);

  const [messages, setMessages] = useState([]);

  const [receivingCall, setReceivingCall] = useState(false);
  const [callerSignal, setCallerSignal] = useState();
  const [yourSocketID, setYourSocketID] = useState(null);

  const [makingCall, setMakingCall] = useState(false);
  const [isVideo, setIsVideo] = useState(true);
  const [isAudio, setIsAudio] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const toggle = () => setPlaying(!playing);

  const pc_config = {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302",
      },
      {
        urls: "stun:stun1.l.google.com:19302",
      },
      {
        urls: "stun:stun2.l.google.com:19302",
      },
    ],
  };

  const [callerID, setCallerID] = useState("");
  const [visible, setVisible] = useState("none");
  const [receiverID, setReceiverID] = useState("");
  // const [pc, setPc] = useState(null)
  const [callActive, setCallActive] = useState(false);
  const [askingForCall, setAskingForCall] = useState(false);
  const [incommingCall, setIncommingCall] = useState(false);
  // const [askingForCall, setAskingForCall] = useState(false)

  const localVideoref = useRef();
  const remoteVideoref = useRef();

  useEffect(() => {
    pc = new RTCPeerConnection(pc_config);
  }, []);

  useEffect(() => {
    let socket = io(variables.baseUrl, {
      query: {
        token: localStorage.getItem("token"),
        id: localStorage.getItem("id"),
      },
    });
    setSocket(socket);
  }, []);

  useEffect(() => {
    if (socket) {
      subscribeForMessages();
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      createConnectionForPeer();
    }
  }, [socket, pc, receiverID, callerID]);

  const subscribeForMessages = () => {
    socket.on("message", (msg) => {
      addMessage(msg);
      if (activeRoute !== "chat") {
        notification["info"]({
          message: msg.text,
          // description:msg.text,
          placement: "bottomRight",
        });
      }
    });
  };
  const createConnectionForPeer = () => {
    if (pc) {
      // let pc = new RTCPeerConnection(pc_config)

      socket.on("askForCall", (data) => {
        callIn.loop = true;
        callIn.play();
        setVisible("");
        setIncommingCall(true);
        pc.setRemoteDescription(new RTCSessionDescription(data.sdp));
        setCallerID(data.callerID);
        setReceiverID(data.receiverID);
      });

      socket.on("pickCall", (data) => {
        callOut.pause();
        setAskingForCall(false);
        pc.setRemoteDescription(new RTCSessionDescription(data.sdp));
      });

      socket.on("candidate", (data) => {
        pc.addIceCandidate(new RTCIceCandidate(data.candidate));
      });

      socket.on("closeCall", () => {
        setVisible("none");
        pc.close();
        stopStreamedVideo(localVideoref);
        setTimeout(() => {
          // pc.restartIce();
          // let pic = null
          // setPc(pic)
          // setTimeout(() => {
          pc = new RTCPeerConnection(pc_config);
          // setPc(pi)
          // }, 1000);
          createConnectionForPeer();
        }, 1000);
      });

      pc.onicecandidate = (e) => {
        if (e.candidate) {
          socket.emit("candidate", {
            candidate: e.candidate,
            callerID: socket.id,
            receiverID: receiverID,
          });
        }
      };

      pc.oniceconnectionstatechange = (e) => {
        console.log(e);
      };
      // pc.onaddstream = (e) => {
      // remoteVideoref.current.srcObject = e.stream
      // }
      pc.ontrack = (e) => {
        remoteVideoref.current.srcObject = e.streams[0];
      };
      pc.onconnectionstatechange = (e) => {
        switch (pc.connectionState) {
          case "connected":
            console.log("CONNECTED");
            // The connection has become fully connected
            break;
          case "disconnected":
            console.log("DISCONNECTED");

          case "failed":
            // One or more transports has terminated unexpectedly or in an error
            console.log("FAIL");
            break;
          case "closed":
            console.log("CLOSE");
            // The connection has been closed
            break;
        }
      };

      const success = (stream) => {
        // if(p.c)
        if (pc.connectionState !== "closed") {
          console.log(pc.connectionState);
          window.localStream = stream;
          localVideoref.current.srcObject = stream;
          pc.addStream(stream);
        }
      };
      // called when getUserMedia() fails - see below
      const failure = (e) => {
        console.log("getUserMedia Error: ", e);
      };

      const constraints = {
        audio: isAudio,
        video: false,
        // video: {
        //   width: 1280,
        //   height: 720
        // },
        // video: {
        //   width: { min: 1280 },
        // }
        options: {
          mirror: false,
        },
      };
      // https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(success)
        .catch(failure);
    }
    // else {
    // let pic = null;
    // setPc(pic)
    // }
  };

  const addMessage = (newMessage) => {
    setMessages((state) => [...state, newMessage]);
    if (newMessage.to === localStorage.getItem("id")) {
      audio.volume = 0.2;
      audio.play();
    }
  };

  /* ACTION METHODS FROM THE BUTTONS ON SCREEN */

  const askForCall = (id) => {
    pc.createOffer({ offerToReceiveVideo: 1 }).then((sdp) => {
      pc.setLocalDescription(sdp);
      setReceiverID(id);
      setCallerID(socket.id);
      setVisible("");
      setIsAudio(true);
      setAskingForCall(true);

      socket.emit("askForCall", {
        receiverID: id,
        callerID: socket.id,
        sdp: sdp,
      });
      callOut.loop = true;
    });
  };

  const pickCall = () => {
    callIn.pause();
    pc.createAnswer({ offerToReceiveVideo: 1 }).then((sdp) => {
      pc.setLocalDescription(sdp);
      setIncommingCall(false);
      socket.emit("pickCall", { sdp: sdp, callerID: callerID });
    });
  };

  const stopStreamedVideo = (videoElem) => {
    // const stream = localVideoref.current.srcObject
    // const remoteStream = remoteVideoref;
    // const tracks = stream.getTracks();
    // console.log(stream)
    // console.log(remoteStream)
    // tracks.forEach(function(track) {
    // track.stop();
    // });
    window.localStream.getTracks().forEach((track) => {
      track.stop();
    });

    localVideoref.current.srcObject = null;
    remoteVideoref.current.srcObject = null;
  };
  const closeCall = () => {
    let users = [];
    if (callerID) {
      users.push(callerID);
    }
    if (receiverID) {
      users.push(receiverID);
    }
    console.log(users);
    socket.emit("closeCall", { callerID: callerID, receiverID: receiverID });
  };

  const updateActiveRoute = (route) => {
    setActiveRoute(route);
  };

  return (
    <CallContext.Provider
      value={{
        messages,
        setMessages,
        socket,
        callerID,
        receiverID,
        askForCall,
        pickCall,
        makingCall,
        receivingCall,
        callerID,
        callerSignal,
        yourSocketID,
        activeRoute,
        updateActiveRoute,
        notifications,
        setNotifications,
      }}
    >
      <>
        <div className="call-wrapper" style={{ display: visible }}>
          <div className="call-wrapper-top">
            <div className="floating-video">
              {/* {UserVideo} */}
              <video
                className="user-video"
                ref={localVideoref}
                autoPlay
                muted
                controls
              ></video>
            </div>
            <div className="flex items-center justify-center">
              <h2 className="white center mg-0">Muhammad Sarmad</h2>
              {/* <div className="white center mg-l-20 " >
                    02:05
                  </div>   */}
              {/* <div className="white center mg-l-20 " >
                    Ringing
                  </div>   */}
            </div>
          </div>

          <div className="call-wrapper-center">
            {askingForCall && <h3 className="center white">Calling...</h3>}
            {incommingCall && (
              <div className="center">
                <h3 className="center white">Incomming Call...</h3>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => pickCall(callerID)}
                >
                  Pick Call
                </Button>
              </div>
            )}
            {/* { startCalling === true && callAccepted !== true ? (
               <h1 className="white" >Calling...</h1>
          ) : startCalling === false && callAccepted !== true && receivingCall ? (
                incomingCall
            ) : (
              PartnerVideo
              )
              } */}
          </div>
          {/* <div className="call-wrapper-center" >
            callinf
              </div> */}
          {
            // Object.keys(users).map(k => {
            //   if (k !== socket.id) {
            //     return (
            //       <button onClick={ () => askForCall(k) }>Call {k}</button>
            //     )
            //   }
            // })
          }

          <video
            className="partner-video"
            ref={remoteVideoref}
            autoPlay
          ></video>

          <div className="call-wrapper-bottom">
            {/* {remoteVideoref ? (
            <Tooltip title="Stop Video">
              <Button  
              // onClick={()=> setVideo(false)}
               type="primary" className="mg-l-20" danger shape="circle" icon={<VideoCameraOutlined />} />
            </Tooltip>
            ): (
           
            <Tooltip title="Start Video">
              <Button 
              //  onClick={()=> setVideo(true)}
               className="header-button mg-l-20" icon={<VideoCameraOutlined />} />
            </Tooltip>   
            )
          } */}

            <Tooltip title="Close Call">
              <Button
                type="primary"
                onClick={() => closeCall()}
                className="mg-l-20"
                danger
                shape="circle"
                icon={<PhoneOutlined />}
              ></Button>
            </Tooltip>
          </div>
        </div>

        {children}
      </>
    </CallContext.Provider>
  );
};
