import React, { useEffect, useState, useContext } from "react";
import { Form, Input, Select, Row, Col, Button, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link, useParams, useHistory } from "react-router-dom";
import { AuthContext, ProfileContext } from "../hooks";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";

const { Option } = Select;
export default function Signup(props) {
  const { t } = useTranslation();

  const languageLists = (t) => {
    return [
      { id: Math.random(), text: "English", translateKey: "en" },
      { id: Math.random(), text: "German", translateKey: "de" },
      { id: Math.random(), text: "French", translateKey: "fr" },
      { id: Math.random(), text: "Spanish", translateKey: "sp" },
    ];
  };

  const languageChangeHandler = (value) => {
    const { i18n } = props;
    i18n.changeLanguage(value);
  };
  const menu = (
    <Menu>
      {languageLists(t).map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => languageChangeHandler(item.translateKey)}
        >
          {item.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const history = useHistory();
  const params = useParams();
  const [isSignUp, setIsSignUp] = useState(true);
  const [title, setTitle] = useState("");
  const [user, setUser] = useState({
    first_name: "",
    second_name: "",
    gender: "",
    email: "",
    password: "",
    country: "",
    countryId: null,
    state: null,
    stateId: null,
    city: null,
    cityId: null,
    status: null,
    about: null,
  });
  const [photo, setPhoto] = useState({
    room_name: "",
    name: "",
    uri: null,
  });
  const {
    doRegister,
    static_first_names,
    static_countries,
    static_states,
    static_cities,
    doGetNames,
    doGetCountries,
    doGetStatesByCountry,
    doGetCitiesByState,
  } = useContext(AuthContext);
  const { profile, doUpdateProfile, doGetProfile } = useContext(ProfileContext);
  useEffect(() => {
    init();

    return () => {};
  }, []);

  useEffect(() => {
    return () => {};
  }, [user]);

  const init = async () => {
    getNames();
    getCountries();

    if (params.method && params.method === "edit") {
      setIsSignUp(false);
      setTitle(t("Update Your Profile"));
      await doGetProfile();
      setProfile();
    } else {
      setIsSignUp(true);
      setTitle(t("Create Your Account"));
    }
  };
  const setProfile = () => {
    setUser(profile);
  };
  const getNames = async () => {
    await doGetNames();
  };

  const getCountries = async () => {
    await doGetCountries();
  };

  const getStatesByCountry = async (id) => {
    for (const c of static_countries) {
      if (c.id === id) {
        setUser({ ...user, country: c.title, countryId: id });
      }
    }
    await doGetStatesByCountry(id);
  };
  const getCitiesByState = async (id) => {
    for (const c of static_states) {
      if (c.id === id) {
        setUser({ ...user, state: c.title, stateId: id });
      }
    }

    await doGetCitiesByState(id);
  };

  const onChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const register = async () => {
    let fd = new FormData();
    if (photo.name !== "") {
      fd.append("photo", {
        uri: photo.uri,
        name: photo.name,
        type: photo.type,
      });
    }
    fd.append("first_name", user.first_name);
    fd.append("second_name", user.second_name);
    fd.append("email", user.email);
    fd.append("password", user.password);
    fd.append("gender", user.gender);
    fd.append("country", user.country);
    fd.append("countryId", user.countryId);
    fd.append("state", user.state);
    fd.append("stateId", user.stateId);
    fd.append("city", user.city);
    fd.append("cityId", user.cityId);
    fd.append("status", user.status);
    fd.append("about", user.about);

    await doRegister(fd);
    setTimeout(() => {
      history.push("/login");
    }, 2000);
  };

  return (
    <>
      <div style={{ position: "absolute", top: "3%", right: "25%" }}>
        <Dropdown overlay={menu}>
          <p
            className="ant-dropdown-link"
            style={{ fontWeight: "bold", fontSize: ".8vw", cursor: "pointer" }}
            onClick={(e) => e.preventDefault()}
          >
            Select Language <DownOutlined />
          </p>
        </Dropdown>
      </div>
      <Row justify="center">
        <Col style={{ marginTop: 60 }} lg={8} md={10} sm={14} xs={18}>
          <p className="center">
            <img
              width="100"
              height="100"
              style={{ borderRadius: 50 }}
              alt="Alter"
              src={require("../assets/images/icon.jpg")}
            />
          </p>
          <Form name="register" scrollToFirstError>
            <Form.Item>
              <h2 className="center">{title}</h2>
            </Form.Item>
            <Form.Item>
              <Select
                showSearch
                className
                placeholder={t("Type First Name")}
                optionFilterProp="children"
                onChange={(first_name) => setUser({ ...user, first_name })}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_first_names.map((n) => {
                  return (
                    <Option key={n.title} value={n.title}>
                      {n.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Input
                placeholder={t("Second Name")}
                name="second_name"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item>
              <Select
                showSearch
                placeholder={t("Type Country")}
                optionFilterProp="children"
                onChange={getStatesByCountry}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_countries.map((c) => {
                  return (
                    <Option key={c.title} value={c.id}>
                      {c.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Select
                showSearch
                placeholder={t("Type State")}
                optionFilterProp="children"
                onChange={getCitiesByState}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_states.map((s) => {
                  return (
                    <Option key={s.title} value={s.id}>
                      {s.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Select
                showSearch
                placeholder={t("Type City")}
                optionFilterProp="children"
                onChange={(city) => setUser({ ...user, city })}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_cities.map((c) => {
                  return (
                    <Option key={c.title} value={c.title}>
                      {c.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item>
              <Input
                placeholder={t("Email")}
                name="email"
                type="email"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item>
              <Input
                placeholder={t("Password")}
                type="password"
                name="password"
                onChange={onChange}
              />
            </Form.Item>

            <Form.Item>
              <div className="flex justify-between items-center">
                <Button onClick={register} type="primary" htmlType="submit">
                  {t("Create Account")}
                </Button>
                <Link to="/login">{t("LOGIN NOW!")}</Link>
              </div>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
}
