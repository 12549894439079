import React, { useContext, createContext, useState, useCallback } from "react";
import {
  login,
  updateProfilePhoto,
  removeFcmToken,
  addFcmToken,
  names,
  countries,
  StatesByCountry,
  citiesByState,
  register,
  changePassword,
  getUserDetail,
  resetPasswordRequest,
  verifyReset,
  resetPassword,
} from "../../api/auth";
import { LoaderContext } from "./Loader";
import { ToastContext } from "./Toast";
import { AppContext } from "./App";
import { variables } from "../../constants/variables";
import io from "socket.io-client";
import { ProfileContext } from "./Profile";
import { getFirebaseDeviceToken } from "../../utils";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [static_first_names, setStatic_first_names] = useState([]);
  const [static_countries, setStatic_Countries] = useState([]);
  const [static_states, setStatic_States] = useState([]);
  const [static_cities, setStatic_Cities] = useState([]);
  // const navigation = useNavigation();
  const { setSocket, navigateTo } = useContext(AppContext);
  const { openToast } = useContext(ToastContext);
  const { setLoader } = useContext(LoaderContext);
  const { doGetProfile } = useContext(ProfileContext);

  const doGetNames = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      names()
        .then(async (res) => {
          let first_names = [];
          for (const n of res.first_names) {
            first_names.push({ title: n });
          }
          setStatic_first_names(first_names);
          setLoader(false);
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetCountries = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      countries()
        .then(async (cntrs) => {
          let ct = [];
          for (const c of cntrs) {
            ct.push({ id: c.id, title: c.name });
          }
          setStatic_Countries(ct);
          setLoader(false);
          setLoader(false);
          resolve(countries);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetStatesByCountry = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      StatesByCountry(id)
        .then(async (states) => {
          let sts = [];
          for (const c of states) {
            sts.push({ id: c.id, title: c.name });
          }
          setStatic_States(sts);
          setLoader(false);
          setLoader(false);
          resolve(states);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetCitiesByState = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      citiesByState(id)
        .then(async (cities) => {
          let cts = [];
          for (const c of cities) {
            cts.push({ id: c.id, title: c.name });
          }
          setStatic_Cities(cts);
          setLoader(false);
          setLoader(false);
          resolve(cities);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);
  const doLogin = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      login(data)
        .then(async (res) => {
          localStorage.setItem("token", res.token);
          localStorage.setItem("id", res.id);
          setLoader(false);
          let socket = io(variables.baseUrl, {
            query: {
              token: res.token,
              id: res.id,
            },
          });
          setSocket(socket);
          await doGetProfile();
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetUser = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getUserDetail(id)
        .then(async (res) => {
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doChangePassword = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      changePassword(data)
        .then(async (res) => {
          openToast("success", "Password Changed Succesfully");
          setLoader(false);
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doResetPasswordRequest = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      resetPasswordRequest(data)
        .then(async (res) => {
          openToast("success", "Password reset link sent to your email.");
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);
  const doVerifyReset = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      verifyReset(data)
        .then(async (res) => {
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);
  const doResetPassword = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      resetPassword(data)
        .then(async (res) => {
          openToast("success", "Password has been reset.");
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doRegister = useCallback((data) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      register(data)
        .then(async (res) => {
          openToast("success", "Your Acount created succesfully.");
          setLoader(false);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doUpdateProfilePhoto = useCallback((image) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      updateProfilePhoto(image)
        .then(async (ret) => {
          setLoader(false);
          resolve(ret);
        })
        .catch(function (err) {
          setLoader(false);
          reject(err);
        });
    });
  }, []);

  const doAddFcmToken = () => {
    return new Promise(async (resolve, reject) => {
      let tkn = await getFirebaseDeviceToken();
      addFcmToken({ token: tkn })
        .then(async (ret) => {
          resolve(ret);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  };

  const doRemoveFcmToken = useCallback(async () => {
    return new Promise(async (resolve, reject) => {
      let tkn = await getFirebaseDeviceToken();

      removeFcmToken({ token: tkn })
        .then(async (ret) => {
          resolve(ret);
        })
        .catch(function (err) {
          reject(err);
        });
    });
  }, []);

  const logout = (data) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem("token");
      resolve(true);
    });
  };

  return (
    <AuthContext.Provider
      value={{
        doGetNames,
        static_first_names,
        doGetCountries,
        static_countries,
        doGetStatesByCountry,
        static_states,
        doGetCitiesByState,
        static_cities,
        doGetUser,
        doChangePassword,
        doLogin,
        doRegister,
        doUpdateProfilePhoto,
        doAddFcmToken,
        doRemoveFcmToken,
        logout,
        doResetPasswordRequest,
        doVerifyReset,
        doResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
