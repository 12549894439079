import React, { useState, useRef, useEffect, useContext } from "react";
import { Input, Layout, Button, Tooltip, Tag } from "antd";
import {
  VideoCameraOutlined,
  MenuFoldOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import PageHeader from "../components/partials/header";
import { callAPI } from "../service";
import { openAlert } from "../service/notification";
import { CallContext } from "../context";
import Call from "../components/call/call";
import axios from "axios";
import { variables } from "../constants/variables";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";

const Chat = () => {
  const { t } = useTranslation();

  const { socket, askForCall, startMedia, messages, setMessages } =
    useContext(CallContext);
  const [loading, setLoading] = useState(false);
  const [activeUserId, setActiveUserId] = useState("");
  const [activeUserName, setActiveUserName] = useState("");
  const [to, setTo] = useState("");
  const [text, setText] = useState("");
  const [users, setUsers] = useState([]);
  // const [messages, setMessages] = useState([])
  const [id, setId] = useState("");
  const messagesEndRef = useRef();

  // COMPONENT DID MOUNT
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  useEffect(() => {
    setId(localStorage.getItem("id"));
    // if (socket) {
    //   socket.on('message', msg => {
    //     // pushMessage(msg)
    //     addMessage(msg)
    //   })
    // }
  }, []);
  // const addMessage = (newMessage) => {
  //   setMessages(state => [...state, newMessage])
  //   scrollToBottom()
  // }

  // const pushMessage = (msg)=>{
  //   setMessages([...messages, msg])
  // }
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "auto" });
  };
  const getUsers = () => {
    setLoading(true);
    callAPI("friends-with-messages", "get", null, null)
      .then((res) => {
        if (res.data.length > 0) {
          setActiveUserId(res.data[0]._id);
          setActiveUserName(
            res.data[0].first_name + " " + res.data[0].second_name
          );
          getMessages(res.data[0]._id);
        }
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          openAlert("error", "Error", err.response.data.message);
        } else if (err.request) {
          openAlert(
            t("error"),
            t("Error"),
            t("Error,Please try again or check you internet connection.")
          );
        } else {
          openAlert(
            t("error"),
            t("Error"),
            t("Error,Please try again or check you internet connection.")
          );
        }
      });
  };

  const selectUser = (user) => {
    if (user._id !== activeUserId) {
      setActiveUserId(user._id);
      setActiveUserName(user.first_name + " " + user.second_name);
      setTo(user._id);
      getMessages(user._id);
    }
  };

  const getMessages = (to) => {
    let id = localStorage.getItem("id");

    setLoading(true);
    callAPI(`/messages/${to}/${id}`)
      .then((res) => {
        setLoading(false);
        let msgs = [];
        for (const msg of res.data) {
          if (msg.from === id) {
            msgs.push({ ...msg, user: true });
          } else {
            msgs.push({ ...msg, user: false });
          }
        }
        setMessages([...msgs].reverse());
        scrollToBottom();
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          openAlert("error", "Error", err.response.data.message);
        } else if (err.request) {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        } else {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        }
      });
  };

  const didReadMessages = () => {
    setLoading(true);
    callAPI(
      `read-messages/${this.props.route.params.user._id}`,
      "put",
      null,
      null
    )
      .then((res) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          openAlert("error", "ERROR", err.response.data.message);
        } else if (err.request) {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        } else {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        }
      });
  };

  const deleteMessage = (msg) => {
    this.setState({ loading: true, ismodalopen: false });
    let token = localStorage.getItem("token");
    let url = `${variables.baseUrl}/message/${this.state.message._id}`;
    axios({
      method: "delete",
      url: url,
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        this.setState({ loading: false });
        let messages = this.state.messages;
        let i = messages.indexOf(this.state.message);
        messages.splice(i, 1);
        this.setState({ messages: messages });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          openAlert("error", "Error", err.response.data.message);
        } else if (err.request) {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        } else {
          openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        }
      });
  };

  const didReadMessagesLocaly = () => {
    this.props.route.params.didReadMessagesLocaly(this.props.route.params.user);
  };
  const stopWriting = () => {
    // Get the input box
    // let input = document.getElementById('my-input');
    // // Init a timeout variable to be used below
    // let timeout = null;
    // // Listen for keystroke events
    // input.addEventListener('keyup', function (e) {
    //     // Clear the timeout if it has already been set.
    //     // This will prevent the previous task from executing
    //     // if it has been less than <MILLISECONDS>
    //     clearTimeout(timeout);
    //     // Make a new timeout set to go off in 1000ms (1 second)
    //     timeout = setTimeout(function () {
    //     }, 1000);z
    // });
  };
  const onSend = () => {
    // socket.emit('TYPING', {to: to, status: false});
    socket.emit("message", {
      // ...messages[0],
      text: text,
      user: { from: id },
      to: activeUserId,
    });

    setText("");
  };
  const dateCheck = (m, index) => {
    if (index === 0) {
      return (
        <div className="center">{new Date(m.createdAt).toDateString()}</div>
      );
    } else {
      if (
        new Date(m.createdAt).getDate() + new Date(m.createdAt).getMonth() !==
        new Date(messages[index - 1].createdAt).getDate() +
          new Date(messages[index - 1].createdAt).getMonth()
      ) {
        return (
          <div className="center">{new Date(m.createdAt).toDateString()}</div>
        );
      }
    }
  };

  return (
    <Layout>
      <PageHeader />
      {/* <Sidebare /> */}
      <div className="chat-sidebare">
        {users.map((u) => {
          return (
            <div
              key={u._id}
              onClick={() => selectUser(u)}
              className={
                activeUserId === u._id ? "chat-user-active" : "chat-user"
              }
            >
              {u.photo ? (
                <img
                  className="header-photo fit-cover"
                  alt="example"
                  src={u.photo}
                />
              ) : (
                <div className="flex flex-row justify-center  items-center header-photo ">
                  <UserOutlined style={{ fontSize: "14px" }} />
                </div>
              )}

              <div className="mg-l-chatuser">
                <div className="bold">
                  {u.first_name} {u.second_name}
                </div>
                <small>
                  {u.city} {u.country}
                </small>
                {Number(u.chats) > 0 && (
                  <Tag className="chat-unread-tag" color="#55acee">
                    {u.chats}
                  </Tag>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="chat-messages-section">
        <div className="chat-top-section">
          {/* <Tooltip title="Video Call">
            <Button
              onClick={() => alert("Making call")}
              className="header-button"
              icon={<VideoCameraOutlined />}
            />
          </Tooltip> */}
          {/* <Tooltip title="Audio Call">
            <Button  onClick={()=>alert('Making call')} className="header-button mg-l-20" icon={<PhoneOutlined />} />
          </Tooltip> */}
          {/* <Call userId={activeUserId} /> */}
          {/* <Tooltip title="Audio Call">
            <Button
              onClick={() => askForCall(activeUserId, "caller")}
              className="header-button mg-l-20"
              icon={<PhoneOutlined />}
            />
          </Tooltip> */}
          <Tooltip title="Menu">
            <Button
              onClick={() => alert("Making call")}
              className="header-button mg-l-20"
              icon={<MenuFoldOutlined />}
            />
          </Tooltip>
        </div>
        <div className="chat-center-section">
          {messages.length === 0 && loading === false && activeUserId && (
            <center>
              <h2>
                {t("This is your first message to")} {activeUserName} !
              </h2>
            </center>
          )}
          {messages.map((m, index) => {
            if (m.from === id) {
              return (
                <>
                  {dateCheck(m, index)}

                  <div className="chat-my-message-wrapper">
                    <div key={m._id} className="chat-message chat-my-message">
                      <div>{m.text}</div>
                      <small className="float-right">
                        {new Date(m.createdAt).toLocaleTimeString([], {
                          hour12: true,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </small>
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  {dateCheck(m, index)}

                  <div className="chat-user-message-wrapper">
                    <div key={m._id} className="chat-message chat-user-message">
                      <div>{m.text}</div>
                      <small className="float-left">
                        {new Date(m.createdAt).toLocaleTimeString([], {
                          hour12: true,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </small>
                    </div>
                  </div>
                </>
              );
            }
          })}
          <div ref={messagesEndRef} />
        </div>

        <div className="chat-bottom-section">
          <div className="flex">
            <form
              style={{ width: "100%" }}
              onSubmit={(e) => {
                e.preventDefault();
                onSend();
              }}
            >
              <Input
                placeholder={t("Type Message...")}
                size="middle"
                value={text}
                onChange={(v) => setText(v.target.value)}
                // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                className="chat-input-field"
              />
            </form>

            <Button
              size="large"
              onClick={onSend}
              className="mg-l-10 send-message-btn"
              type="primary"
            >
              {t("Send")}
            </Button>
          </div>
        </div>
        {/* <div className="messages-header" >
          <Tooltip title="Video Call">
            <Button  onClick={()=>alert('Making call')} className="header-button" icon={<VideoCameraOutlined/>} />
          </Tooltip>
          <Tooltip title="Audio Call">
            <Button  onClick={()=>alert('Making call')} className="header-button" icon={<PhoneOutlined />} />
          </Tooltip>

          <Tooltip title="Menu">
            <Button  onClick={()=>alert('Making call')} className="header-button" icon={<MenuFoldOutlined />} />
          </Tooltip>
        </div>
      
      
          
        {
          messages.length === 0 && loading === false && activeUserId && (
            <center><h2> This is your first message to {activeUserName} !</h2></center>
           )
        }
        {
          messages.map(m => {
            if (m.from === id) {
              return (
                <div className="chat-my-message-wrapper" >
                  <div key={m._id} className="chat-message chat-my-message" >
                    <div>{m.text}</div>
                  </div>
                </div>
              )
            } else {
              return (
                <div key={m._id} className="chat-message chat-user-message" >
                  <div>{m.text}</div>
                </div>
              )
            }
          })
        }
        <div className="chat-input" >
          <div className="flex" >

            <form style={{ width: '100%' }} onSubmit={e => { e.preventDefault(); onSend() }} >
            <Input
                placeholder="Type Message..."
                size="middle"
                onChange={(v)=> setState({message:  v.target.value}) }
                // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                className="chat-input-field"
            />
          </form>
            
          <Button size="large" onClick={onSend} className="mg-l-10 send-message-btn" type="primary" >
            Send
          </Button>
          </div>
          
        </div> */}
      </div>
    </Layout>
  );
};

// const mapStatesToProps = state => {
//   // alert(JSON.stringify(state.config))
//   return {
//     profile: state.profile,
//     config: state.config,
//   };
// };
export default withRouter(Chat);
