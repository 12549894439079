import { service } from "../utils/request";

export function getAllPosts(id) {
  return service({
    url: "/posts",
    method: "get",
  });
}

export function getUserPosts(id) {
  return service({
    url: `/user-posts/${id}`,
    method: "get",
  });
}

export function getPost(id) {
  return service({
    url: `/post/${id}`,
    method: "get",
  });
}

export function addPost(data) {
  return service({
    url: "/post",
    method: "post",
    data: data,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
}

export function editPost(id) {
  return service({
    url: `/post/${id}`,
    method: "put",
  });
}

export function deletePost(id) {
  return service({
    url: `/post/${id}`,
    method: "delete",
  });
}

export function addComment({ id, comment }) {
  return service({
    url: `/comment-post/${id}`,
    method: "put",
    data: { text: comment },
  });
}

export function likePost(id) {
  return service({
    url: `/like-post/${id}`,
    method: "put",
  });
}

export function unLikePost(id) {
  return service({
    url: `/unlike-post/${id}`,
    method: "put",
  });
}
