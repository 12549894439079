import React, { useContext, useState, useEffect } from "react";
import { Card, Layout, Modal } from "antd";
import PageHeader from "../components/partials/header";
import AddPostModal from "../components/addpost";
import Item from "../components/home/item";
import ItemModal from "../components/home/itemModal";
import { AppContext, PostContext, ProfileContext } from "../hooks";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { allPosts, doGetAllPosts } = useContext(PostContext);
  const { doAddDevice, doGetProfile } = useContext(ProfileContext);
  const { activeVideo, doActiveVideo, doActiveTab } = useContext(AppContext);
  const [post, setPost] = useState(null);
  useEffect(() => {
    getLatestPosts();
    addDevice();
    getProfile();
    return () => {};
  }, []);
  const getProfile = async () => {
    await doGetProfile();
  };
  const getLatestPosts = async () => {
    await doGetAllPosts();
  };
  const addDevice = async () => {
    // await doAddDevice();
  };

  const onViewableItemsChanged = ({ viewableItems, changed }) => {
    let len = viewableItems.length;
    if (len === 1) {
      if (viewableItems[0].item.videos.length >= 1) {
        doActiveVideo(viewableItems[0].index);
      } else {
        doActiveVideo(-1);
      }
    }
    if (len === 2) {
      // When Fist Photos and Send Video
      if (
        viewableItems[0].item.videos.length === 0 &&
        viewableItems[1].item.videos.length >= 1
      ) {
        doActiveVideo(viewableItems[1].index);

        // Both Video
      } else if (
        viewableItems[0].item.videos.length >= 1 &&
        viewableItems[1].item.videos.length >= 1
      ) {
        doActiveVideo(viewableItems[0].index);
      } // Both Photos
      else if (
        viewableItems[0].item.videos.length === 0 &&
        viewableItems[1].item.videos.length === 0
      ) {
        doActiveVideo(-1);
        // Both Videos
      } else if (
        viewableItems[0].item.videos.length >= 1 &&
        viewableItems[1].item.videos.length === 0
      ) {
        doActiveVideo(viewableItems[0].index);
      }
    }
    if (len === 3) {
      doActiveVideo(viewableItems[2].index);
    }
  };

  const openPost = (post) => {
    setPost(post);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Layout>
      <PageHeader t={t} />
      {/* <Sidebare /> */}
      <Modal
        title="Basic Modal"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ItemModal pst={post} t={t} />
      </Modal>
      <div className="home-center-contents">
        <div className="mb-10 home-toolbar ">
          <Card>
            <AddPostModal t={t} />
          </Card>
        </div>
        {allPosts.map((post) => {
          if (post.user) {
            return (
              <Item openPost={openPost} key={post._id} post={post} t={t} />
            );
          }
        })}
      </div>

      {/* <div className="home-right" >

        <div className="home-sidebare-item" >
            <HomeOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Home
            </div>
        </div>
        <div className="home-sidebare-item" >
            <UserOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Profile
            </div>
        </div>

        <div className="home-sidebare-item" >
            <UsergroupDeleteOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Friends
            </div>
        </div>


        <div className="home-sidebare-item" >
            
            <MessageOutlined style={{fontSize:18}}  />
            <div className="mg-l-30 bold" >
              Messenges
            </div>
        </div>

        </div>
  
   */}
    </Layout>
  );
}

// import React, { useEffect, useState, useRef, useContext } from 'react';
// // import './App.css';
// import io from "socket.io-client";
// import Peer from "simple-peer";
// import styled from "styled-components";
// import { AppContext } from '../context';

// const Container = styled.div`
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// `;

// const Row = styled.div`
//   display: flex;
//   width: 100%;
// `;

// const Video = styled.video`
//   border: 1px solid blue;
//   width: 50%;
//   height: 50%;
// `;

// function App() {
//   const [yourID, setYourID] = useState("");
//   const [users, setUsers] = useState({});
//   const [stream, setStream] = useState();
//   const [receivingCall, setReceivingCall] = useState(false);
//   const [caller, setCaller] = useState("");
//   const [callerSignal, setCallerSignal] = useState();
//   const [callAccepted, setCallAccepted] = useState(false);

//   const userVideo = useRef();
//   const partnerVideo = useRef();
//   // const socket = useRef();
//   const { socket } = useContext( AppContext )

//   useEffect(() => {
//     // socket.current = io.connect("http://localhost:3700/");
//     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
//       setStream(stream);
//       if (userVideo.current) {
//         userVideo.current.srcObject = stream;
//       }
//     })
//     if (socket) {

//     socket.on("yourID", (id) => {
//       setYourID(id);
//     })
//     socket.on("allUsers", (users) => {
//       setUsers(users);
//     })

//     socket.on("hey", (data) => {
//       setReceivingCall(true);
//       setCaller(data.from);
//       setCallerSignal(data.signal);
//     })
//     }
//   }, [socket]);

//   function callPeer(id) {
//     const peer = new Peer({
//       initiator: true,
//       trickle: false,
//       config: {

//         iceServers: [
//             {
//                 urls: "stun:numb.viagenie.ca",
//                 username: "sultan1640@gmail.com",
//                 credential: "98376683"
//             },
//             {
//                 urls: "turn:numb.viagenie.ca",
//                 username: "sultan1640@gmail.com",
//                 credential: "98376683"
//             }
//         ]
//     },
//       stream: stream,
//     });

//     peer.on("signal", data => {
//       socket.emit("callUser", { userToCall: id, signalData: data, from: yourID })
//     })

//     peer.on("stream", stream => {
//       if (partnerVideo.current) {
//         partnerVideo.current.srcObject = stream;
//       }
//     });

//     socket.on("callAccepted", signal => {
//       setCallAccepted(true);
//       peer.signal(signal);
//     })

//   }

//   function acceptCall() {
//     setCallAccepted(true);
//     const peer = new Peer({
//       initiator: false,
//       trickle: false,
//       stream: stream,
//     });
//     peer.on("signal", data => {
//       socket.emit("acceptCall", { signal: data, to: caller })
//     })

//     peer.on("stream", stream => {
//       partnerVideo.current.srcObject = stream;
//     });

//     peer.signal(callerSignal);
//   }

//   let UserVideo;
//   if (stream) {
//     UserVideo = (
//       <Video playsInline muted ref={userVideo} autoPlay />
//     );
//   }

//   let PartnerVideo;
//   if (callAccepted) {
//     PartnerVideo = (
//       <Video playsInline ref={partnerVideo} autoPlay />
//     );
//   }

//   let incomingCall;
//   if (receivingCall) {
//     incomingCall = (
//       <div>
//         <h1>{caller} is calling you</h1>
//         <button onClick={acceptCall}>Accept</button>
//       </div>
//     )
//   }
//   return (
//     <Container>
//       <Row>
//         {UserVideo}
//         {PartnerVideo}
//       </Row>
//       <Row>
//         {Object.keys(users).map(key => {
//           if (key === yourID) {
//             return null;
//           }
//           return (
//             <button onClick={() => callPeer(key)}>Call {key}</button>
//           );
//         })}
//       </Row>
//       <Row>
//         {incomingCall}
//       </Row>
//     </Container>
//   );
// }

// export default App;
