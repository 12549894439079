import React from "react";
import { notification, Card, Button, Layout, Carousel } from "antd";
import axios from "axios";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import { addProfile } from "../actions/profile";
import { UserOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import PageHeader from "../components/partials/header";
import Sidebare from "../components/partials/sidebare";
import { callAPI } from "../service";
import { variables } from "../constants/variables";
import { t } from "i18next";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "/home",
      loading: false,
      refresh: false,
      posts: [],
    };
  }
  componentDidMount() {
    this.getLatestPosts();
  }

  getLatestPosts() {
    this.setState({ loading: true });
    callAPI("/posts", "get", null)
      .then((res) => {
        this.setState({ posts: res.data });
        this.setState({ loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        if (err.response) {
          this.openAlert("error", "Error", err.response.data.message);
        } else if (err.request) {
          this.openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        } else {
          this.openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        }
      });
  }

  openAlert(type, title, msg) {
    notification[type]({
      message: title,
      description: msg,
    });
  }

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let len = viewableItems.length;
    if (len === 1) {
      if (viewableItems[0].item.videos.length >= 1) {
        this.props.activeVideo(viewableItems[0].index);
      } else {
        this.props.activeVideo(-1);
      }
    }
    if (len === 2) {
      // When Fist Photos and Send Video
      if (
        viewableItems[0].item.videos.length === 0 &&
        viewableItems[1].item.videos.length >= 1
      ) {
        this.props.activeVideo(viewableItems[1].index);

        // Both Video
      } else if (
        viewableItems[0].item.videos.length >= 1 &&
        viewableItems[1].item.videos.length >= 1
      ) {
        this.props.activeVideo(viewableItems[0].index);
      } // Both Photos
      else if (
        viewableItems[0].item.videos.length === 0 &&
        viewableItems[1].item.videos.length === 0
      ) {
        this.props.activeVideo(-1);
        // Both Videos
      } else if (
        viewableItems[0].item.videos.length >= 1 &&
        viewableItems[1].item.videos.length === 0
      ) {
        this.props.activeVideo(viewableItems[0].index);
      }
    }
    if (len === 3) {
      this.props.activeVideo(viewableItems[2].index);
    }
  };

  async likeUnlikePost(id, action) {
    this.setState({ likeloader: true, loader: true });
    let token = localStorage.getItem("token");
    let url;
    if (action === "like") {
      url = `${variables.baseUrl}/like-post/${id}`;
    } else {
      url = `${variables.baseUrl}/unlike-post/${id}`;
    }
    axios({
      method: "put",
      url: url,
      headers: { Authorization: `Token ${token}` },
    })
      .then((res) => {
        this.setState({ likeloader: false, loader: false });
        this.props.addProfile(res.data);
        if (action === "like") {
          this.setState({ likes: this.state.likes + 1 }, () => {});
        } else {
          this.setState({ likes: this.state.likes - 1 });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        if (err.response) {
          this.openAlert("e", err.response.data.message);
        } else if (err.request) {
          this.openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        } else {
          this.openAlert(
            t("error"),
            t("Error"),
            t("Someting wrong, try again or check your intenet connection.")
          );
        }
      });
  }

  isFavorate(likes, post_id, likeloader) {
    if (likeloader) {
      return <Button>{t("Loading")}</Button>;
    } else {
      let meet = false;
      if (likes) {
        for (const l of likes) {
          if (post_id === l.post) {
            meet = true;
            return (
              <Button
                onClick={this.likeUnlikePost.bind(this, post_id, "unlike")}
                type="primary"
              >
                {t("LIKE")}
              </Button>
            );
          }
        }
      }

      if (meet === false) {
        return (
          <Button
            onClick={this.likeUnlikePost.bind(this, post_id, "like")}
            type="default"
          >
            {t("LIKE")}
          </Button>
        );
      }
    }
  }

  navigateTo(nav) {
    this.props.history.push(nav);
  }

  render() {
    return (
      <Layout>
        <PageHeader />
        <Sidebare />
        <div className="center-contents">
          {this.state.posts.map((p) => {
            return (
              <Card
                key={p._id}
                style={{ width: "100%", marginBottom: 10 }}
                cover={
                  <div>
                    <div className="post-header pd-15">
                      <div className="flex flex-row items-center ">
                        {p.user.photo ? (
                          <img
                            className="post-logo fit-cover"
                            alt="example"
                            src={p.user.photo}
                          />
                        ) : (
                          <div
                            className="flex flex-row justify-center  items-center"
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: 15,
                              backgroundColor: "lightgrey",
                            }}
                          >
                            <UserOutlined style={{ fontSize: "14px" }} />
                          </div>
                        )}
                        <div className="mg-l-10">
                          {p.user && p.user.first_name}{" "}
                          {p.user && p.user.second_name}{" "}
                        </div>
                      </div>

                      <div className="mg-t-10">{p.text}</div>
                    </div>
                    <div>
                      {p.photos.length > 0 ? (
                        <Carousel>
                          {p.photos.map((ph) => {
                            return (
                              <div key={ph._id}>
                                <img width="100%" alt="example" src={ph.url} />
                              </div>
                            );
                          })}
                        </Carousel>
                      ) : (
                        <div></div>
                      )}
                      {p.videos && p.videos.length > 0 && (
                        <ReactPlayer
                          controls
                          className="video-player"
                          playing={false}
                          width="100%"
                          url={p.videos[0].url}
                        />
                      )}
                    </div>
                  </div>
                }
              >
                <div>
                  <div className="flex flex-row justify-between">
                    <div>
                      {p.likes} {t("Likes")}{" "}
                    </div>
                    <div>
                      {p.comments} {t("Comments")}
                    </div>
                  </div>
                  <div className="mg-t-10">
                    {this.isFavorate(
                      this.props.profile.likes,
                      p._id,
                      this.state.likeloader
                    )}
                    <Button className="mg-l-10">{t("Comments")}</Button>
                  </div>
                </div>
              </Card>
            );
          })}
        </div>

        {/* <div className="home-right" >

        <div className="home-sidebare-item" >
            <HomeOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Home
            </div>
        </div>
        <div className="home-sidebare-item" >
            <UserOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Profile
            </div>
        </div>

        <div className="home-sidebare-item" >
            <UsergroupDeleteOutlined style={{fontSize:18}} />
            <div className="mg-l-30 bold" >
              Friends
            </div>
        </div>


        <div className="home-sidebare-item" >
            
            <MessageOutlined style={{fontSize:18}}  />
            <div className="mg-l-30 bold" >
              Messenges
            </div>
        </div>

        </div>
  
   */}
      </Layout>
    );
  }
}

const mapStatesToProps = (state) => {
  // alert(JSON.stringify(state.config))
  return {
    profile: state.profile,
    config: state.config,
  };
};
export default connect(mapStatesToProps, { addProfile })(withRouter(Home));

// import React, { useEffect, useState, useRef, useContext } from 'react';
// // import './App.css';
// import io from "socket.io-client";
// import Peer from "simple-peer";
// import styled from "styled-components";
// import { AppContext } from '../context';

// const Container = styled.div`
//   height: 100vh;
//   width: 100%;
//   display: flex;
//   flex-direction: column;
// `;

// const Row = styled.div`
//   display: flex;
//   width: 100%;
// `;

// const Video = styled.video`
//   border: 1px solid blue;
//   width: 50%;
//   height: 50%;
// `;

// function App() {
//   const [yourID, setYourID] = useState("");
//   const [users, setUsers] = useState({});
//   const [stream, setStream] = useState();
//   const [receivingCall, setReceivingCall] = useState(false);
//   const [caller, setCaller] = useState("");
//   const [callerSignal, setCallerSignal] = useState();
//   const [callAccepted, setCallAccepted] = useState(false);

//   const userVideo = useRef();
//   const partnerVideo = useRef();
//   // const socket = useRef();
//   const { socket } = useContext( AppContext )

//   useEffect(() => {
//     // socket.current = io.connect("http://localhost:3700/");
//     navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
//       setStream(stream);
//       if (userVideo.current) {
//         userVideo.current.srcObject = stream;
//       }
//     })
//     if (socket) {

//     socket.on("yourID", (id) => {
//       setYourID(id);
//     })
//     socket.on("allUsers", (users) => {
//       setUsers(users);
//     })

//     socket.on("hey", (data) => {
//       setReceivingCall(true);
//       setCaller(data.from);
//       setCallerSignal(data.signal);
//     })
//     }
//   }, [socket]);

//   function callPeer(id) {
//     const peer = new Peer({
//       initiator: true,
//       trickle: false,
//       config: {

//         iceServers: [
//             {
//                 urls: "stun:numb.viagenie.ca",
//                 username: "sultan1640@gmail.com",
//                 credential: "98376683"
//             },
//             {
//                 urls: "turn:numb.viagenie.ca",
//                 username: "sultan1640@gmail.com",
//                 credential: "98376683"
//             }
//         ]
//     },
//       stream: stream,
//     });

//     peer.on("signal", data => {
//       socket.emit("callUser", { userToCall: id, signalData: data, from: yourID })
//     })

//     peer.on("stream", stream => {
//       if (partnerVideo.current) {
//         partnerVideo.current.srcObject = stream;
//       }
//     });

//     socket.on("callAccepted", signal => {
//       setCallAccepted(true);
//       peer.signal(signal);
//     })

//   }

//   function acceptCall() {
//     setCallAccepted(true);
//     const peer = new Peer({
//       initiator: false,
//       trickle: false,
//       stream: stream,
//     });
//     peer.on("signal", data => {
//       socket.emit("acceptCall", { signal: data, to: caller })
//     })

//     peer.on("stream", stream => {
//       partnerVideo.current.srcObject = stream;
//     });

//     peer.signal(callerSignal);
//   }

//   let UserVideo;
//   if (stream) {
//     UserVideo = (
//       <Video playsInline muted ref={userVideo} autoPlay />
//     );
//   }

//   let PartnerVideo;
//   if (callAccepted) {
//     PartnerVideo = (
//       <Video playsInline ref={partnerVideo} autoPlay />
//     );
//   }

//   let incomingCall;
//   if (receivingCall) {
//     incomingCall = (
//       <div>
//         <h1>{caller} is calling you</h1>
//         <button onClick={acceptCall}>Accept</button>
//       </div>
//     )
//   }
//   return (
//     <Container>
//       <Row>
//         {UserVideo}
//         {PartnerVideo}
//       </Row>
//       <Row>
//         {Object.keys(users).map(key => {
//           if (key === yourID) {
//             return null;
//           }
//           return (
//             <button onClick={() => callPeer(key)}>Call {key}</button>
//           );
//         })}
//       </Row>
//       <Row>
//         {incomingCall}
//       </Row>
//     </Container>
//   );
// }

// export default App;
