import React, { Component, useContext, useEffect } from "react";
import { env } from "../assets/env";
import axios from "axios";
import { useHistory, withRouter } from "react-router-dom";
import { notification } from "antd";
import io from "socket.io-client";
import { AppContext, ProfileContext } from "../hooks";
import { variables } from "../constants/variables";

const Splash = () => {
  const history = useHistory();
  const { setSocket } = useContext(AppContext);
  const { doGetProfile } = useContext(ProfileContext);
  useEffect(() => {
    checkToken();
    return () => {};
  }, []);
  const goTo = (nav) => {
    history.push(nav);
  };
  const checkToken = async () => {
    let token = localStorage.getItem("token");
    if (token) {
      await doGetProfile()
        .then(async () => {
          goTo("/home");
          let socket = io(variables.baseUrl, {
            query: {
              token: localStorage.getItem("token"),
              id: localStorage.getItem("id"),
            },
          });
          setSocket(socket);
        })
        .catch(() => {
          goTo("/login");
        });
    } else {
      goTo("/login");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        style={{ width: 150, height: 150 }}
        alt="Splash Screen"
        src={require("../assets/images/icon.jpg")}
      />
    </div>
  );
};

export default withRouter(Splash);
