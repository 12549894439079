import { ADD_PPROFILE } from "../config/reduxvariables";
const initialState = {};
export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PPROFILE:
      state = action.profile;
      return { ...state };
    // case ADD_ITEM_TO_PACKOUT:
    //     return {...state,packout:{ ...state.packout,items:[...state.packout.items,action.item]}};
    // case ADD_PHOTO:
    //     return {...state,photo:action.photo};
    // case ADD_ITEMS:
    //     return {...state,items:action.items};
    // case ADD_ITEM_TO_ITEMS:
    //     return {...state,items:[...state.items,action.item]};
    // case ADD_ROOM_TO_PACKOUT:
    //     return {...state,packout:{ ...state.packout,photos:[...state.packout.photos,action.room]}};
    // case DELETE_ROOM_TO_PACKOUT:{
    //     let photos = state.packout.photos;
    //     let index = photos.findIndex(p => p._id === action.id);
    //             photos.splice(index,1);
    //         if(photos.length === 0){
    //             return {...state,packout:{ ...state.packout,photos:[...photos]},photo:{url:'',_id:''}};
    //         } else if(photos.length === 1){
    //             return {...state,packout:{ ...state.packout,photos:[...photos]},photo: photos[0]};
    //         } else {
    //             if( photos.length < index+1 ){
    //                 return {...state,packout:{ ...state.packout,photos:[...photos]},photo: photos[index - 1]};
    //             } else {
    //                 return {...state,packout:{ ...state.packout,photos:[...photos]},photo: photos[index]};
    //             }
    //         }
    //     }
    // case ADD_ITEM_FROM_PACKOUT:{
    //     for (const i of state.packout.items) {
    //         if(i._id===action.id){
    //             return {...state,item: i };
    //         }
    //     }
    // }
    // case UPDATE_ITEM_TO_PACKOUT: {
    //     let index = state.packout.items.findIndex(i => i._id === action.item._id);
    //     state.packout.items.splice(index,1);
    //     return {...state,packout:{ ...state.packout,items:[...state.packout.items,action.item]}};
    // }
    // case DELETE_ITEM_FROM_PACKOUT:{

    //     let index = state.packout.items.findIndex(i => i._id === action.id);
    //     state.packout.items.splice(index,1);
    //     return {...state};

    // }
    default:
      return { ...state };
  }
};
