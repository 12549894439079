import React, { useEffect, useContext } from "react";
import {
  HomeOutlined,
  MenuOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  UserOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { CallContext } from "../../context";
import { useHistory } from "react-router-dom";
import "./style.scss";
const CenterNavigations = ({ t }) => {
  const history = useHistory();
  const { activeRoute, updateActiveRoute } = useContext(CallContext);
  const navigateTo = (r) => {
    updateActiveRoute(r);
    history.push(`/${r}`);
  };

  return (
    <div className="header-navigation">
      {activeRoute === "home" ? (
        <div
          onClick={() => navigateTo("home")}
          className="nav-item active-item"
        >
          <HomeOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("home")} className="nav-item">
          <HomeOutlined style={{ fontSize: 18 }} />
        </div>
      )}
      {activeRoute === "profile" ? (
        <div
          onClick={() => navigateTo("profile")}
          className="nav-item active-item"
        >
          <UserOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("profile")} className="nav-item">
          <UserOutlined style={{ fontSize: 18 }} />
        </div>
      )}

      {activeRoute === "finduser" ? (
        <div
          onClick={() => navigateTo("finduser")}
          className="nav-item active-item"
        >
          <SearchOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("finduser")} className="nav-item">
          <SearchOutlined style={{ fontSize: 18 }} />
        </div>
      )}

      {activeRoute === "chat" ? (
        <div
          onClick={() => navigateTo("chat")}
          className="nav-item active-item"
        >
          <MessageOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("chat")} className="nav-item">
          <MessageOutlined style={{ fontSize: 18 }} />
        </div>
      )}
      {activeRoute === "famewall" ? (
        <div
          onClick={() => navigateTo("famewall")}
          className="nav-item active-item"
        >
          <UsergroupAddOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("famewall")} className="nav-item">
          <UsergroupAddOutlined style={{ fontSize: 18 }} />
        </div>
      )}

      {activeRoute === "menu" ? (
        <div
          onClick={() => navigateTo("menu")}
          className="nav-item active-item"
        >
          <MenuOutlined style={{ fontSize: 18 }} />
        </div>
      ) : (
        <div onClick={() => navigateTo("menu")} className="nav-item">
          <MenuOutlined style={{ fontSize: 18 }} />
        </div>
      )}
    </div>
  );
};
export default CenterNavigations;
