import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import { LoaderContext } from "./Loader";
import {
  addComment,
  deletePost,
  editPost,
  getPost,
  getUserPosts,
  getAllPosts,
  likePost,
  unLikePost,
} from "../../api/post";
import { ToastContext } from "./Toast";
import { ProfileContext } from "./Profile";
export const PostContext = createContext();
export const PostProvider = ({ children }) => {
  const [post, setPost] = useState({
    text: "",
    photos: [],
    user: {
      first_name: "",
      second_name: "",
    },
    likes: [],
    comments: [],
  });

  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const { openToast } = useContext(ToastContext);
  const { setLoader } = useContext(LoaderContext);
  const { profile, doGetProfile } = useContext(ProfileContext);
  useEffect(() => {}, []);

  const doGetAllPosts = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      // setLoader(true);
      getAllPosts()
        .then((psts) => {
          // setLoader(false);
          setAllPosts(psts);
          resolve(psts);
        })
        .catch((err) => {
          // setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetUserPosts = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getUserPosts(id)
        .then((psts) => {
          setLoader(false);
          setPosts(psts);
          resolve(psts);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doGetPost = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);

      getPost(id)
        .then((pst) => {
          setLoader(false);
          setPost(pst);
          resolve(pst);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doDeletePost = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      deletePost(id)
        .then(async (pst) => {
          setLoader(false);
          openToast("error", "Post Deleted");
          await getUserPosts(profile._id);
          resolve(pst);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doEditPost = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      editPost(id)
        .then(async (pst) => {
          setLoader(false);
          openToast("error", "Post Updated");
          await getUserPosts(profile._id);
          setPost(pst);
          resolve(pst);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const doAddComment = useCallback((payload) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);

      addComment(payload)
        .then((post) => {
          setLoader(false);
          doGetPost(payload.id);
          resolve(post);
        })
        .catch((err) => {
          setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);

  const increaseCommentsCountOnPost = useCallback(
    (postId, comment) => {
      return new Promise(async (resolve, reject) => {
        let posts = [];
        for (const pst of allPosts) {
          if (pst._id === postId) {
            pst.comments += 1;
            posts.push(pst);
          } else {
            posts.push(pst);
          }
        }
        setAllPosts(posts);
        resolve(true);
      });
    },
    [allPosts]
  );
  const doLikePost = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      // setLoader(true);
      likePost(id)
        .then(async () => {
          // setLoader(false);
          resolve(post);
          // await doGetProfile();
        })
        .catch((err) => {
          // setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);
  const doUnLikePost = useCallback((id) => {
    return new Promise(async (resolve, reject) => {
      // setLoader(true);

      unLikePost(id)
        .then(async () => {
          // setLoader(false);
          resolve(post);
          // await doGetProfile();
        })
        .catch((err) => {
          // setLoader(false);
          openToast("error", err);
          reject(err);
        });
    });
  }, []);
  return (
    <PostContext.Provider
      value={{
        doGetUserPosts,
        doGetAllPosts,
        post,
        posts,
        allPosts,
        doDeletePost,
        doEditPost,
        doGetPost,
        doAddComment,
        doLikePost,
        doUnLikePost,
        increaseCommentsCountOnPost,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
