import React, {
  useState,
  useEffect,
  createContext,
  useContext,
} from 'react';
import {getMessages} from '../../api/chat';
import {AppContext} from './App';
import {LoaderContext} from './Loader';
import {ProfileContext} from './Profile';
import {ToastContext} from './Toast';
export const ChatContext = createContext();
export const ChatProvider = ({children}) => {
  const {setLoader} = useContext(LoaderContext);
  const {profile} = useContext(ProfileContext);
  const {socket} = useContext(AppContext);
  const {openToast} = useContext(ToastContext);
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    setMessages([]);
    if (socket) {
      socket.off('message');
      socket.on('message', (data) => {
        let user = null;
        if (data.from === profile._id) {
          user = {
            _id: data.from,
          };
          setMessages((msgs) => [{...data, user}, ...msgs]);
        } else if (data.to === profile._id) {
          setMessages((msgs) => [ {...data, user}, ...msgs]);
        }
      });
    }
    return () => {
      if (socket) {
        socket.off('message');
      }
    };
  }, [socket,profile]);
  const doGetMessages = (payload) => {
    return new Promise(async (resolve, reject) => {
      setLoader(true);
      getMessages(payload)
        .then(async (res) => {
          setLoader(false);
          let msgs = [];
          for (const msg of res) {
            if (msg.from === profile._id) {
              msgs.push({...msg, user: {_id: profile._id}});
            } else {
              msgs.push(msg);
            }
          }
          setMessages(msgs);
          resolve(res);
        })
        .catch((err) => {
          setLoader(false);
          openToast('error', err);
          reject(err);
        });
    });
  };
  const doSendMessage = (data) => {
    return new Promise(async (resolve, reject) => {
      socket.emit('message', data);
      resolve();
    });
  };
  return (
    <ChatContext.Provider
      value={{
        messages,
        doSendMessage,
        doGetMessages,
      }}>
      {children}
    </ChatContext.Provider>
  );
};
