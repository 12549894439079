import React, {
  useState,
  createContext,
} from 'react';
export const AppContext = createContext();
export const AppProvider = ({children}) => {
  const [socket, setSocket] = useState(null);
  const [activeVideo, setActiveVideo] = useState(0)
  const [activeRoute, setActiveRoute] = useState('/')
  const [activeTab, setActiveTab] = useState(1)
  const doActiveVideo = (index) => {
    setActiveVideo(index)
  }
  const doActiveTab = (index) => {
    setActiveTab(index)
  }
  return (
    <AppContext.Provider
      value={{
        socket,
        setSocket,
        activeVideo,
        doActiveVideo,
        activeTab,
        doActiveTab,
        activeRoute,
        setActiveRoute
      }}>
      {children}
    </AppContext.Provider>
  );
};
