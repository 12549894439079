import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Tabs,
  Button,
  Tooltip,
  Layout,
  Row,
  Col,
  Carousel,
  Alert,
  Input,
  Form,
  Select,
  Upload,
  Modal,
  message,
} from "antd";
import { stripeKey } from "../index";
import { accountDelete } from "../api/auth";
import ReactPlayer from "react-player";
import PageHeader from "../components/partials/header";
import StripeElement from "../components/stripe/stripeElement";
import { UserOutlined, EditOutlined, CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
// ============== Stripe ============== //
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";
import axios from "axios";
import { PostContext, ProfileContext, AuthContext } from "../hooks";
import Axios from "axios";

const { TabPane } = Tabs;
const { Option } = Select;

export default function Profile() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    profile,
    setProfile,
    doGetProfile,
    friends,
    doAcceptFriendRequest,
    doSendFriendRequest,
    doCancelFriendRequest,
    friendRequestsReceived,
    friendRequestsSent,
    doUnFriend,
    doUpdateProfile,
  } = useContext(ProfileContext);
  const {
    static_first_names,
    doGetNames,
    static_cities,
    static_countries,
    static_states,
    doGetCountries,
    doGetStatesByCountry,
    doGetCitiesByState,
  } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const [showUserName, setShowUserName] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [showprofileEdit, setShowprofileEdit] = useState(false);
  const [User, setUser] = useState({});
  const [file, setFile] = useState([]);
  const [token, setToken] = useState("");
  const [genders, setGenders] = useState([
    { title: "Male" },
    { title: "Female" },
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const cancelSubscription = () => {
    Axios.get(`https://myvisionsapp.com/api/subscription/cancel`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    }).then((resp) => {
      setUser({ ...User, plan: "Basic" });
      message.success("Plan Unsubscribed Successfully");
    });
  };
  const { posts, doGetUserPosts } = useContext(PostContext);
  useEffect(() => {
    init();
    const token = localStorage.getItem("token");
    setToken(token);
    return () => {};
  }, []);

  const init = async () => {
    try {
      let profile = await doGetProfile();
      setUser(profile);
      await doGetUserPosts(profile._id);
      await doGetNames();
      await getCountries();
    } catch (error) {}
  };
  const updateProfile = async () => {
    let data = {
      first_name: profile.first_name,
      second_name: profile.second_name,
      gender: profile.gender,
      country: profile.country,
      countryId: profile.countryId,
      state: profile.state,
      stateId: profile.stateId,
      city: profile.city,
      cityId: profile.cityId,
      status: profile.status,
      // about: profile.about,
    };
    await doUpdateProfile(data);
  };
  const cancelFriendRequest = async (to) => {
    await doCancelFriendRequest(to);
  };

  const unFriend = async (to) => {
    await doUnFriend(to);
  };
  const sendFriendRequest = async (to) => {
    await doSendFriendRequest(to);
  };

  const acceptFriendRequest = async (from) => {
    await doAcceptFriendRequest(from);
  };

  const askDeletePost = (id) => {
    this.deletePost(id);
  };
  const onFileChange = (info) => {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-2);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });
  };

  const onClickAccountDelete = (id) => {
    return new Promise(async (resolve, reject) => {
      let token = localStorage.getItem("token");
      accountDelete({ token: token })
        .then(async (ret) => {
          resolve(ret);
          localStorage.removeItem("token");
          history.push("/login");
        })
        .catch(function (err) {
          reject(err);
        });
    });
  };

  const isFavorate = (likes, post_id, likeloader) => {
    if (likeloader) {
      return <Button>{t("Loading")}</Button>;
    } else {
      let meet = false;
      if (likes) {
        for (const l of likes) {
          if (post_id === l.post) {
            meet = true;
            return (
              <Button
                // onClick={this.likeUnlikePost.bind(this, post_id, 'unlike')}
                type="primary"
              >
                {t("LIKE")}
              </Button>
            );
          }
        }
      }

      if (meet === false) {
        return (
          <Button
            // onClick={this.likeUnlikePost.bind(this, post_id, 'like')}
            type="default"
          >
            {t("LIKE")}
          </Button>
        );
      }
    }
  };

  const getCountries = async () => {
    await doGetCountries();
  };

  const getStatesByCountry = async (id) => {
    for (const c of static_countries) {
      if (c.id === id) {
        setProfile({ ...profile, country: c.title, countryId: id });
      }
    }
    await doGetStatesByCountry(id);
  };
  const getCitiesByState = async (id) => {
    for (const c of static_states) {
      if (c.id === id) {
        setProfile({ ...profile, state: c.title, stateId: id });
      }
    }

    await doGetCitiesByState(id);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    // file.forEach((file) => {
    formData.append("photo", file);
    // });
    setUploading(true);
    // You can use any AJAX library you like
    const token = await localStorage.getItem("token");
    axios
      .put(`http://167.172.100.125/update-profile-photo`, formData, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setFile([]);
        doGetProfile();
        setUploading(false);
      })
      .catch(() => {
        setUploading(false);
      });
  };

  const props = {
    onRemove: (file) => {
      setFile([]);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    file,
  };

  const closeModal = (closed = false) => {
    setIsModalVisible();
  };
  return (
    <Layout>
      {/* <div style={{height:200,width:300}}>
      <label className="custom-file-upload">
          <input type="file" multiple onChange={onFileChange} />
          <i className="fa fa-cloud-upload" /> Attach
        </label>
      </div> */}
      <PageHeader />
      <Modal
        title="Verify Account"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Elements stripe={stripeKey}>
          <StripeElement
            closeModal={closeModal}
            user={User}
            updateUser={setUser}
          ></StripeElement>
        </Elements>
      </Modal>
      {profile && (
        <div className="center-contents-profile">
          <div style={{ padding: 0, marginTop: 50, position: "relative" }}>
            <div className="profile-page-photo-wrapper">
              {profile && profile.photo ? (
                <img
                  className="profile-page-photo"
                  src={profile.photo}
                  alt="Profile Pic"
                />
              ) : (
                <div className="flex flex-row justify-center items-center profile-page-photo">
                  <UserOutlined style={{ color: "white", fontSize: "25px" }} />
                </div>
              )}

              <div className="profile-photo-edit">
                <Upload {...props} multiple={false} showUploadList={false}>
                  <Tooltip title="Edit">
                    <Button
                      onClick={() => {}}
                      className="header-button"
                      icon={<EditOutlined />}
                    />
                  </Tooltip>
                </Upload>
              </div>
            </div>
            <div className="center-contents-upload">
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={file.length === 0}
                loading={uploading}
                // style={{ marginTop: 16}}
              >
                {uploading ? "Uploading" : "Start Upload"}
              </Button>
            </div>
            <div className="flex justify-center items-center mg-t-30">
              <center>
                <div className="flex flex-row items-center ">
                  {showUserName ? (
                    <>
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.first_name}
                            placeholder={t("Type First Name")}
                            onChange={(first_name) =>
                              setProfile({ ...profile, first_name })
                            }
                          >
                            {static_first_names.map((n) => {
                              return (
                                <Option key={n.title} value={n.title}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          <Input
                            placeholder="Type Message..."
                            value={profile?.second_name}
                            size="middle"
                            onChange={(v) => {
                              setProfile({
                                ...profile,
                                second_name: v.target.value,
                              });
                            }}
                            // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                            className="chat-input-field"
                          />
                        </Form.Item>
                      </Form>
                      {/* <Input
                        placeholder="Type Message..."
                        value={profile.first_name}
                        size="middle"
                        // onChange={(v) => setState({ message: v.target.value })}
                        // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                        className="chat-input-field"
                      /> */}
                    </>
                  ) : (
                    <p>
                      {profile.first_name} {profile.second_name}
                    </p>
                  )}
                  <Button
                    className="header-button"
                    type="default"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      // setProfile({
                      //   ...profile,
                      //   second_name: "askdjklkajsdkalsj",
                      // });
                      setShowUserName(!showUserName);
                    }}
                  ></Button>
                </div>
                {/* <p className="f16" >
                          {profile.city} {profile.state} {profile.country}
                        </div> */}
                <div>
                  {showStatus ? (
                    <Input
                      placeholder="Type Status..."
                      value={profile?.status}
                      size="middle"
                      onChange={(v) => {
                        setProfile({
                          ...profile,
                          status: v.target.value,
                        });
                      }}
                      // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                      className="chat-input-field"
                    />
                  ) : (
                    <>
                      {profile.status != "null" ? profile.status : "Add Status"}{" "}
                    </>
                  )}
                  <Button
                    className="header-button"
                    type="default"
                    shape="circle"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setShowStatus(!showStatus);
                    }}
                  ></Button>
                </div>
              </center>
            </div>
          </div>
          {/* <Card className="mg-t-10"
                    >
                    <div className="flex justify-between" >

                        <div className="f16" >
                          <div className="bold" >About</div>
                          {profile.about && user.about != 'null' ? user.about : 'You have Nohting to show.'} 
                        </div>

                      <Button type="primary" shape="circle" icon={<EditOutlined />} size={"middle"} />
                      </div>

                </Card>    */}
          {/* <Card className="mg-t-10"
                    >
                      <div className=" flex flex-row justify-between " >
                        <div className="f16 bold" >
                          <div >KARMA POINTS</div>
                          <div className="center" >
                            {profile.karma} 
                          </div>
                        </div>

                        <div className="f16 bold" >
                          <div>FRIENDS</div>
                          <div className="center" >
                            {profile.friends.length} 
                          </div>
                        </div>

                        <div className="f16 bold" >
                          <div>POSTS</div>
                          <div className="center" >
                            {profile.posts} 
                          </div>
                        </div>
                      </div>

                </Card>    */}
          <Tabs defaultActiveKey="1" centered>
            <TabPane tab="About" key="1">
              <div className="center-contents-profile-posts">
                <Card className="mg-t-20">
                  <div className="profile-about-edit">
                    <Tooltip title="Edit">
                      <Button
                        className="header-button"
                        icon={<EditOutlined />}
                        onClick={() => {
                          setShowprofileEdit(!showprofileEdit);
                        }}
                      />
                    </Tooltip>
                  </div>

                  <div className="flex justify-between ">
                    <div className="bold"> {t("First Name")}</div>
                    {showprofileEdit ? (
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.first_name}
                            placeholder={t("Type First Name")}
                            onChange={(first_name) =>
                              setProfile({ ...profile, first_name })
                            }
                          >
                            {static_first_names.map((n) => {
                              return (
                                <Option key={n.title} value={n.id}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <div>{profile.first_name}</div>
                    )}
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold"> {t("Second Name")}</div>
                    {showprofileEdit ? (
                      <Input
                        placeholder="Type Message..."
                        value={profile?.second_name}
                        size="middle"
                        onChange={(v) => {
                          setProfile({
                            ...profile,
                            second_name: v.target.value,
                          });
                        }}
                        // addonAfter={<PlusOutlined onClick={()=> alert()} />}
                        className="chat-input-field"
                      />
                    ) : (
                      <div>{profile.second_name}</div>
                    )}
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold"> {t("Country")}</div>
                    {showprofileEdit ? (
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.country}
                            placeholder={t("Type Country")}
                            onChange={(c) => getStatesByCountry(c)}
                          >
                            {static_countries.map((n) => {
                              return (
                                <Option key={n.title} value={n.id}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <div>{profile.country}</div>
                    )}
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold"> {t("State")}</div>
                    {showprofileEdit ? (
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.state}
                            placeholder={t("Type State")}
                            onChange={(c) => getCitiesByState(c)}
                          >
                            {static_states.map((n) => {
                              return (
                                <Option key={n.title} value={n.id}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <div>{profile.state}</div>
                    )}
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold"> {t("City")}</div>
                    {showprofileEdit ? (
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.city}
                            placeholder={t("Type City")}
                            onChange={(city) =>
                              setProfile({ ...profile, city })
                            }
                          >
                            {static_cities.map((n) => {
                              return (
                                <Option key={n.title} value={n.title}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <div>{profile.city}</div>
                    )}
                  </div>
                  <div className="flex justify-between mg-t-20">
                    <div className="bold"> {t("Gender")}</div>
                    {showprofileEdit ? (
                      <Form
                        name="register"
                        className="flex flex-row items-center "
                        scrollToFirstError
                      >
                        <Form.Item className="selectable-form">
                          <Select
                            value={profile?.gender}
                            placeholder={"Select Gender"}
                            onChange={(gender) =>
                              setProfile({ ...profile, gender })
                            }
                          >
                            {genders.map((n) => {
                              return (
                                <Option key={n.title} value={n.title}>
                                  {n.title}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Form>
                    ) : (
                      <div>{profile.gender}</div>
                    )}
                  </div>
                </Card>
              </div>
            </TabPane>
            <TabPane tab="Friends" key="2">
              <div className="search-user-wrapper">
                {friends.map((u) => {
                  return (
                    <Card className="mg-t-20 pointer  bg w-50 ">
                      <div className="flex items-center justify-between ">
                        <div className="flex items-center">
                          {u.user.photo ? (
                            <img
                              className="header-photo fit-cover"
                              alt="example"
                              src={u.user.photo}
                            />
                          ) : (
                            <div className="flex flex-row justify-center  items-center header-photo ">
                              <UserOutlined style={{ fontSize: "14px" }} />
                            </div>
                          )}
                          <div className="mg-l-20">
                            <div className="bold">
                              {" "}
                              {u.user.first_name} {u.user.second_name}{" "}
                            </div>
                            <div className="dim">
                              {u.user.city}, {u.user.country}{" "}
                            </div>
                          </div>
                        </div>
                        <Button type="primary" icon={<CheckOutlined />}>
                          {t("FRIEND")}
                        </Button>
                      </div>
                    </Card>
                  );
                })}
                {friends.length === 0 && (
                  <center>
                    <h3>{t("Not have any friend")}</h3>
                  </center>
                )}
              </div>
            </TabPane>
            <TabPane tab="Friends Request" key="3">
              <Tabs className="mg-t-20" centered tabPosition={"top"}>
                <TabPane tab="Sent" key="1">
                  <Row gutter={5}>
                    {friendRequestsSent.map((u) => {
                      return (
                        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card className="mg-b-20 pointer  bg">
                            <div className="flex items-center justify-between ">
                              <div className="flex items-center">
                                {u.photo ? (
                                  <img
                                    className="header-photo fit-cover"
                                    alt="example"
                                    src={u.photo}
                                  />
                                ) : (
                                  <div className="flex flex-row justify-center  items-center header-photo ">
                                    <UserOutlined
                                      style={{ fontSize: "14px" }}
                                    />
                                  </div>
                                )}
                                <div className="mg-l-20">
                                  <div className="bold">
                                    {" "}
                                    {u.to.first_name} {u.to.second_name}{" "}
                                  </div>
                                  <div className="dim">{u.to.city} </div>
                                </div>
                              </div>
                              <Button
                                onClick={() => cancelFriendRequest(u.to)}
                                type="primary"
                                danger
                              >
                                {t("Cancel Request")}
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                    {friendRequestsSent.length === 0 && (
                      <center>
                        <h3>{t("Nothing found.")}</h3>
                      </center>
                    )}
                  </Row>
                </TabPane>
                <TabPane tab="Received" key="2">
                  <Row gutter={5}>
                    {friendRequestsReceived.map((u) => {
                      return (
                        <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                          <Card className="mg-b-20 pointer  bg">
                            <div className="flex items-center justify-between ">
                              <div className="flex items-center">
                                {u.photo ? (
                                  <img
                                    className="header-photo fit-cover"
                                    alt="example"
                                    src={u.photo}
                                  />
                                ) : (
                                  <div className="flex flex-row justify-center  items-center header-photo ">
                                    <UserOutlined
                                      style={{ fontSize: "14px" }}
                                    />
                                  </div>
                                )}
                                <div className="mg-l-20">
                                  <div className="bold">
                                    {" "}
                                    {u.from.first_name} {u.from.second_name}{" "}
                                  </div>
                                  <div className="dim">
                                    {u.from.city}, {u.from.country}{" "}
                                  </div>
                                </div>
                              </div>
                              <Button
                                type="primary"
                                onClick={() => acceptFriendRequest(u.from._id)}
                              >
                                {t("Accept Request")}
                              </Button>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                    {friendRequestsReceived.length === 0 && (
                      <h3>{t("Not have any friend request")}</h3>
                    )}
                  </Row>
                </TabPane>
              </Tabs>
            </TabPane>
            <TabPane tab="Posts" key="4">
              <div className="center-contents-profile-posts">
                {posts.map((p) => {
                  return (
                    <Card
                      key={p._id}
                      style={{ width: "100%", marginBottom: 10 }}
                      cover={
                        <div>
                          <div className="post-header pd-15">
                            <div className="flex flex-row items-center ">
                              {p.user.photo ? (
                                <img
                                  className="post-logo fit-cover"
                                  alt="example"
                                  src={p.user.photo}
                                />
                              ) : (
                                <div
                                  className="flex flex-row justify-center  items-center"
                                  style={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: 15,
                                    backgroundColor: "lightgrey",
                                  }}
                                >
                                  <UserOutlined style={{ fontSize: "14px" }} />
                                </div>
                              )}
                              <div className="mg-l-10">
                                {p.user && p.user.first_name}{" "}
                                {p.user && p.user.second_name}{" "}
                              </div>
                            </div>

                            <div className="mg-t-10">{p.text}</div>
                          </div>
                          <div>
                            {p.photos.length > 0 ? (
                              <Carousel>
                                {p.photos.map((ph) => {
                                  return (
                                    <div key={ph._id}>
                                      <img
                                        width="100%"
                                        alt="example"
                                        src={ph.url}
                                      />
                                    </div>
                                  );
                                })}
                              </Carousel>
                            ) : (
                              <div></div>
                            )}
                            {p.videos && p.videos.length > 0 && (
                              <ReactPlayer
                                className="video-player"
                                playing={false}
                                width="100%"
                                url={p.videos[0].url}
                              />
                            )}
                          </div>
                        </div>
                      }
                    >
                      <div>
                        <div className="flex flex-row justify-between">
                          <div>
                            {p.likes} {t("Likes")}
                          </div>
                          <div>
                            {p.comments} {t("Comments")}
                          </div>
                        </div>
                        <div className="mg-t-10">
                          <Button className="mg-l-10">{t("Comment")}</Button>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </TabPane>
          </Tabs>
          <div className="flex items-center justify-center mg-t-20 mg-b-20">
            <Button onClick={onClickAccountDelete}>
              {t("Deactivate Button")}
            </Button>
            {User?.plan === "Basic" && (
              <Button style={{ marginLeft: "4px" }} onClick={showModal}>
                Verify Account
              </Button>
            )}
            {User?.plan !== "Basic" && (
              <Button
                style={{ marginLeft: "4px" }}
                onClick={cancelSubscription}
              >
                Unsuscribe Account
              </Button>
            )}
          </div>
          {(showStatus || showUserName || showprofileEdit) && (
            <div className="flex items-center justify-center mg-t-20 mg-b-20">
              <Button onClick={updateProfile}>{t("Update Profile")}</Button>
            </div>
          )}
        </div>
      )}{" "}
    </Layout>
  );
}
