import axios from "axios";
import { env } from "../assets/env";
import { variables } from "../constants/variables";
let AUTH_TOKEN;

axios.defaults.baseURL = variables.baseUrl;
export const callAPI = async (url, method, data, headers) => {
  AUTH_TOKEN = await localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    axios({
      method: method,
      url: `${url}`,
      data: data,
      headers: {
        Authorization: `Token ${AUTH_TOKEN}`,
        ...headers,
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
