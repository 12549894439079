import { Spin } from 'antd';
import React, {useState, createContext} from 'react';
export const LoaderContext = createContext();
export const LoaderProvider = ({children}) => {
  const [loader, setLoader] = useState(false);
  return (
    <LoaderContext.Provider value={{ loader, setLoader }}>
      <Spin spinning={loader}  >
        {children}
       </Spin>
        
    </LoaderContext.Provider>
  );
};
