import { service } from "../utils/request";

export function getProfile() {
  return service({
    url: "/profile",
    method: "get",
  });
}
export function updateProfile(data) {
  return service({
    url: "/user",
    method: "put",
    data: data,
  });
}
export function addDevice(data) {
  return service({
    url: "/user/device",
    method: "put",
    data: data,
  });
}

export function removeDevice(data) {
  return service({
    url: "/user/device",
    method: "delete",
    data: data,
  });
}

export function updatePhoto(data) {
  return service({
    url: "/user/photo",
    method: "post",
    data: data,
    config: {
      headers: { "Content-Type": "multipart/form-data" },
    },
  });
}

export function getFriends() {
  return service({
    url: "/friends",
    method: "get",
  });
}

export function getUserFriends(id) {
  return service({
    url: `/friends/${id}`,
    method: "get",
  });
}

export function getFriendRequests() {
  return service({
    url: "/friend-requests",
    method: "get",
  });
}

export function searchUser(string) {
  return service({
    url: `/search/${string}`,
    method: "get",
  });
}
export function searchUserAdvance(data) {
  return service({
    url: "/advance-search",
    method: "post",
    data: data,
  });
}

export function sendFriendRequest(to) {
  return service({
    url: `/friend-request/${to}`,
    method: "post",
  });
}

export function cancelFriendRequest(to) {
  return service({
    url: `/cancel-friend-request/${to}`,
    method: "post",
  });
}

export function acceptFriendRequest(from) {
  return service({
    url: `/add-friend/${from}`,
    method: "post",
  });
}

export function unFriend(to) {
  return service({
    url: `/un-friend/${to}`,
    method: "post",
  });
}

export function getFriendsWithMessages() {
  return service({
    url: `/friends-with-messages`,
    method: "get",
  });
}
export function getFamewall() {
  return service({
    url: "/famewall",
    method: "get",
  });
}
