import React, { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Login from "../pages/login";

export const AuthGuard = ({ children }) => {
  const token = localStorage.getItem("token");
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!token) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Redirect to={requestedLocation} />;
  }

  return <>{children}</>;
};
AuthGuard.propTypes = {
  children: PropTypes.node,
};
