import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Input,
  notification,
  Button,
  Checkbox,
  Row,
  Col,
  Spin,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import {
  Link,
  withRouter,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { connect } from "react-redux";
import { addProfile } from "../actions/profile";
import { env } from "../assets/env";
import axios from "axios";
import { AuthContext } from "../hooks";
import { t } from "i18next";

export default function ResetPassword() {
  const history = useHistory();
  const [newpassword, setNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const { doVerifyReset, doResetPassword } = useContext(AuthContext);
  let location = useLocation();
  const resetNewPassword = async () => {
    await doResetPassword({ email, newpassword });
    history.push("/login");
  };
  useEffect(() => {
    verifyHash();
    return () => {};
  }, []);
  const verifyHash = async () => {
    let urlString = location.search.split("hash=");
    try {
      let res = await doVerifyReset({ hash: urlString[1] });
      setEmail(res.email);
      setIsVerified(true);
    } catch (error) {
      setIsVerified(false);
    }
  };

  return (
    <Spin spinning={false}>
      <Row justify="center">
        {isVerified ? (
          <Col lg={8} md={10} sm={16} xs={18} style={{ marginTop: 90 }}>
            <p className="center bold f16 black">{t("Reset Your Password")}</p>
            <Form
              name="normal_login"
              className="width-100 mg-t-20"
              initialValues={{
                remember: true,
              }}
              onFinish={resetNewPassword}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("Please input your new password!"),
                  },
                ]}
              >
                <Input
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={t("New Password")}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Create new Password")}
              </Button>
            </Form>
          </Col>
        ) : (
          <Col lg={8} md={10} sm={16} xs={18} style={{ marginTop: 90 }}>
            <center>
              <h3>{t("Invalid Hash: please try again!")}</h3>
            </center>
          </Col>
        )}
      </Row>
    </Spin>
  );
}
