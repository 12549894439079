import React, { useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Button } from "antd";

import Axios from "axios";

const StripeElement = ({ closeModal, user, updateUser }) => {
  const stripeHook = useStripe();
  const elements = useElements();
  const Base_Url = "https://api.stripe.com/v1";
  const [token, setToken] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    setToken(token);
    return () => {};
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const createToken = await stripeHook
      .createToken(elements.getElement(CardElement))
      .then((resp) => {
        if (resp) {
          const param = {
            source: resp.token.id,
          };
          Axios.post(`${Base_Url}/customers`, param, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer " + "sk_test_UALc3HoxM5pPNjtmB8Xog3RS",
            },
          }).then((_data) => {
            Axios.post(
              `https://myvisionsapp.com/api/subscription/create`,
              {
                customer_id: _data.data.id,
                plan: "Pro",
              },
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: `Bearer ${token}`,
                },
              }
            ).then((res) => {
              closeModal(false);
              updateUser({ ...user, plan: "Pro" });
            });
          });
        }
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <CardElement />
        <Button style={{ marginTop: "12px" }} onClick={handleSubmit}>
          Pay Now
        </Button>
      </form>
    </>
  );
};

export default StripeElement;
