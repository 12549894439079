import React, {createContext} from 'react';
import { notification} from 'antd';
export const ToastContext = createContext();
export const ToastProvider = ({children}) => {


  const openToast = (type, msg,) => {
    notification[type]({
      // message: title,
      description:msg
    });
  };

  return (
    <ToastContext.Provider value={{ openToast}}>
      {children}
    </ToastContext.Provider>
  );
};
