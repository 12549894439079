import React, { useState } from "react";
import { Modal, Tooltip, Button, Card } from "antd";
import { NotificationOutlined } from "@ant-design/icons";

const Notifications = (props) => {
  // const [previewImage, setPreviewImage] = useState('');
  // const [previewTitle, setPreviewTitle] = useState('');

  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setVisible(true);
  };
  const handleOk = () => {};

  const handleCancel = () => {
    setVisible(false);
    setConfirmLoading(false);
  };

  // const handlePreview = async (name, url) => {
  //   setPreviewImage(url)
  //   setPreviewVisible(true)
  //   setPreviewTitle(name)
  // };

  //   const openFile = (event) => {
  //   setPhotos(Array.from(event.target.files))

  // };
  const { t } = props;

  return (
    <>
      <Tooltip title={t("Notification")}>
        <Button
          onClick={() => showModal()}
          className="header-button"
          icon={<NotificationOutlined />}
        />
      </Tooltip>

      <Modal
        okText={"Upload"}
        title="Notifications"
        visible={visible}
        onOk={handleOk}
        style={{ top: 20 }}
        footer={false}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <div className="clearfix">
          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>

          <Card>
            <div>{t("You updated your profile photo.")}</div>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default Notifications;
