export const firebaseConfig = {
  apiKey: "AIzaSyDHxe8omrIWthoM6sOfB5ebNis4a_Jm-OE",
  authDomain: "blackmail-007.firebaseapp.com",
  databaseURL: "https://blackmail-007.firebaseio.com",
  projectId: "blackmail-007",
  storageBucket: "blackmail-007.appspot.com",
  messagingSenderId: "126753789257",
  appId: "1:126753789257:web:d5be75e516d6ea8399f698",
};
// export const firebaseConfig = {
//     apiKey: "AIzaSyDDKgC-zgLI5WZrRzb517rQNnUoSvgtads",
//     authDomain: "metro-tech.firebaseapp.com",
//     databaseURL: "https://metro-tech.firebaseio.com",
//     projectId: "metro-tech",
//     storageBucket: "metro-tech.appspot.com",
//     messagingSenderId: "96772177794",
//     appId: "1:96772177794:web:a7367c4f73a65a98b5d99b",
//     measurementId: "G-VXFSW7KVQW"
//   };
