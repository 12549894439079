import React, { useContext, useEffect, useState } from "react";
import { Button, Layout, Select, Card, Collapse, Input, Row, Col } from "antd";
import PageHeader from "../components/partials/header";
import { UserOutlined, CaretRightOutlined } from "@ant-design/icons";
import { AuthContext, ProfileContext } from "../hooks";
// ============== Trnaslation ============== //
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;
const { Option } = Select;

export default function FindUser() {
  const { t } = useTranslation();

  const [first_name, setFirst_name] = useState(null);
  const [city, setCity] = useState(null);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [searchString, setSearchString] = useState("");
  const {
    friends,
    friendsList,
    search,
    doAcceptFriendRequest,
    doSendFriendRequest,
    doCancelFriendRequest,
    friendRequestsReceivedList,
    friendRequestsSentList,
    doGetFriends,
    doSearchUsers,
    doSearchUsersAdvance,
    doGetFriendRequests,
    doUnFriend,
  } = useContext(ProfileContext);

  const {
    static_first_names,
    static_countries,
    static_states,
    static_cities,
    doGetNames,
    doGetCountries,
    doGetStatesByCountry,
    doGetCitiesByState,
  } = useContext(AuthContext);

  useEffect(() => {
    init();
    return () => {};
  }, []);

  const init = async () => {
    await Promise.all[
      (await doGetFriends(),
      await doGetFriendRequests(),
      await doGetNames(),
      await doGetCountries())
    ];
  };
  const getStatesByCountry = async (id) => {
    for (const c of static_countries) {
      if (c.id === id) {
        setCountry(c.title);
      }
    }
    await doGetStatesByCountry(id);
  };
  const getCitiesByState = async (id) => {
    for (const c of static_states) {
      if (c.id === id) {
        setState(c.title);
      }
    }

    await doGetCitiesByState(id);
  };

  const applyFilters = (filters) => {
    setFirst_name(filters.first_name);
    setCity(filters.city);
  };
  const applySearch = async () => {
    if (first_name !== null && city !== null) {
      await doSearchUsersAdvance({ first_name, city });
    } else {
      if (searchString.length > 0) {
        await doSearchUsers(searchString);
      } else {
        // alert('Please enter search string');
      }
    }
  };

  const cancelFriendRequest = async (to) => {
    await doCancelFriendRequest(to);
  };

  const unFriend = async (to) => {
    await doUnFriend(to);
  };
  const sendFriendRequest = async (to) => {
    await doSendFriendRequest(to);
  };

  const acceptFriendRequest = async (from) => {
    await doAcceptFriendRequest(from);
  };
  const clearFilter = () => {
    setFirst_name(null);
    setCity(null);
    setState(null);
    setCountry(null);
  };
  const renderActionBtn = (id) => {
    if (friendsList.includes(id)) {
      return (
        <Button onClick={() => unFriend(id)} type="primary">
          {t("Un Friend")}
        </Button>
      );
    } else if (friendRequestsSentList.includes(id)) {
      return (
        <Button onClick={() => cancelFriendRequest(id)} type="primary">
          {t("Cancel Request")}
        </Button>
      );
    } else if (friendRequestsReceivedList.includes(id)) {
      return (
        <Button onClick={() => acceptFriendRequest(id)} type="primary">
          {t("Accept")}
        </Button>
      );
    } else {
      return (
        <Button onClick={() => sendFriendRequest(id)} type="primary">
          {t("Add Friend")}
        </Button>
      );
    }
  };
  return (
    <Layout>
      <PageHeader />
      {/* <Sidebare /> */}
      <div className="center-contents-full">
        <Row className="mg-b-20">
          <Col span={18} offset={3}>
            <div className="flex">
              <Input
                value={searchString}
                placeholder={t("Search user by name...")}
                onChange={(e) => setSearchString(e.target.value)}
              />
              <Button
                onClick={() => applySearch()}
                type="primary"
                className="mg-l-10"
              >
                {t("Search")}
              </Button>
            </div>
          </Col>
        </Row>

        <Collapse
          bordered={false}
          // defaultActiveKey={['1']}
          expandIconPosition={"right"}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          <Panel
            header={t("Advance Search")}
            key="1"
            className="site-collapse-custom-panel"
          >
            <div className="flex flex-row justify-between items-center">
              {/* <div className="flex justify-between flex-column " > */}
              <Select
                showSearch
                style={{ width: 180 }}
                placeholder={t("Type First Name")}
                optionFilterProp="children"
                value={first_name}
                onChange={(name) => setFirst_name(name)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_first_names.map((n) => {
                  return (
                    <Option key={n.title} value={n.title}>
                      {n.title}
                    </Option>
                  );
                })}
              </Select>

              {/* </div> */}

              {/* <div className="flex justify-between flex-column " > */}
              <Select
                showSearch
                style={{ width: 180 }}
                placeholder={t("Type Country")}
                optionFilterProp="children"
                value={country}
                onChange={getStatesByCountry}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_countries.map((c) => {
                  return (
                    <Option key={c.title} value={c.id}>
                      {c.title}
                    </Option>
                  );
                })}
              </Select>
              <Select
                showSearch
                style={{ width: 180 }}
                placeholder={t("Type State")}
                value={state}
                optionFilterProp="children"
                onChange={getCitiesByState}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_states.map((s) => {
                  return (
                    <Option key={s.title} value={s.id}>
                      {s.title}
                    </Option>
                  );
                })}
              </Select>
              <Select
                showSearch
                style={{ width: 180 }}
                value={city}
                placeholder={t("Type City")}
                optionFilterProp="children"
                onChange={(c) => setCity(c)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {static_cities.map((c) => {
                  return (
                    <Option key={c.title} value={c.title}>
                      {c.title}
                    </Option>
                  );
                })}
              </Select>
              {/* </div> */}
              {/* <div > */}
              <Button
                className="mg-l-10"
                onClick={clearFilter}
                type="default"
                danger
              >
                {t("Clear Filters")}
              </Button>
              <Button
                className="mg-l-10"
                onClick={() => applyFilters()}
                type="primary"
              >
                {t("GO")}
              </Button>
              {/* </div> */}
            </div>
          </Panel>
        </Collapse>

        <Row gutter={5}>
          {search.map((u) => {
            return (
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <Card className="mg-t-20 pointer bg">
                  <div className="flex items-center justify-between ">
                    <div className="flex items-center">
                      {u.photo ? (
                        <img
                          className="header-photo fit-cover"
                          alt="example"
                          src={u.photo}
                        />
                      ) : (
                        <div className="flex flex-row justify-center  items-center header-photo ">
                          <UserOutlined style={{ fontSize: "14px" }} />
                        </div>
                      )}
                      <div className="mg-l-20">
                        <div className="bold">
                          {" "}
                          {u.first_name} {u.second_name}{" "}
                        </div>
                        <div className="dim">{u.city} </div>
                      </div>
                    </div>

                    {renderActionBtn(u._id)}
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Layout>
  );
}
